import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as Chats } from "../../../assets/icons/dashboard/chats.svg";
import { ReactComponent as SecondIcon } from "../../../assets/icons/dashboard/joblisting.svg";
import { ReactComponent as ThirdIcon } from "../../../assets/icons/dashboard/service-offering.svg";
import ClientExpanded from "../../../assets/images/usertypes/client-expanded.png";
import ProviderExpanded from "../../../assets/images/usertypes/client-expanded.png";
import EnterpriseExpanded from "../../../assets/images/usertypes/client-expanded.png";
import fetch from "../../../axios/manager";
import * as JwtManager from "../../../cache/jwt";
import showToast from "../../../dialogs/loader/toast";
import { getFcmToken, subscribeToTopic } from "../../../integrations/firebaseconfig";
import DashboardItem from "../components/dashboard-item";

const Dashboard = () => {

    const userSlice = useSelector((state) => state.userSlice);
    const user = userSlice.user;

    const type = user.userType;
    const userTypeExpandedImg = getMainImage(type);
    const mainTitle = getTitleText(type);

    const notificationSlice = useSelector(state => state.notificationsSlice);

    useEffect(() => {
        Notification.requestPermission();
    }, []);

    const mainItems = [
        {
            "icon": Chats,
            "label": "Chats",
            "to": "/chatRooms",
            "notificationCount": notificationSlice.notificationCount,
        },
        {
            "icon": SecondIcon,
            "label": type != "provider" ? "Find A Professional" : "My Service Offering",
            "to": type != "provider" ? "/FindAProfessional" : "/MyServiceOffering",
        },
        {
            "icon": ThirdIcon,
            "label": type != "provider" ? "My Job Listings" : "Look For Work",
            "to": type != "provider" ? "/MyJobListing" : "/LookForWork",
        },
    ];

    useEffect(() => {
        fetchJwt();

        topicSubscribe();
    }, [user]);

    async function topicSubscribe() {
        if (user.userType == "") return;

        const token = await getFcmToken();

        if (token != null) {
            await subscribeToTopic(token, `user~${user.userType}`);
            await subscribeToTopic(token, "user~all");
        }
    }

    async function fetchJwt() {
        if (user.token == "") return;

        const prevToken = await JwtManager.getToken();

        if (prevToken == null) {
            const [jwtData, jwtError] = await fetch({
                route: `users/getToken/${user.token}`,
                requestType: "get",
            });

            if (jwtError != null) {
                showToast(jwtError);
                return;
            }

            const newJwtToken = jwtData.token;

            JwtManager.setToken({ jwt: newJwtToken });
        }
    }

    return <main>
        <section>
            <div className="w-[100%] xl:h-[780px] h-[700px] max-sm:h-[500px] relative">
                <div className="absolute h-[100%] w-[100%]" style={{ background: "linear-gradient(90deg, rgba(0,0,0,0.6502976190476191) 0%, rgba(0,0,0,0) 100%)" }}></div>
                <img loading="lazy" className="object-cover h-[100%] w-[100%] xl:object-center" src={userTypeExpandedImg} alt="user type" />
                <div className="absolute h-[100%] w-[100%] top-0 left-0">
                    <div className="flex flex-row max-lg:flex-col max-lg:gap-10 justify-between my-[7%] mx-[100px] max-lg:mx-[50px]">
                        <div className="flex flex-col uppercase text-white">
                            <h1 className=" text-8xl font-light">{mainTitle[0]}</h1>
                            <h1 className="text-8xl font-semibold">{mainTitle[1]}</h1>
                        </div>
                        <div className="bg-white rounded-primary p-10 xl:w-[40%] lg:w-[50%] min-w-[500px]" style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.1)" }}>
                            <h1 className="text-4xl font-medium text-Gray46">Hi, <span className="text-black">{userSlice.profile.firstName}.</span></h1>
                            <div className="flex flex-col gap-4 mt-10">
                                {mainItems.map((item) => {
                                    return (<DashboardItem key={item.label} item={item} />);
                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </main>;
};

function getMainImage(type) {
    switch (type) {
        case "client":
            return ClientExpanded;
        case "provider":
            return ProviderExpanded;
        default:
            return EnterpriseExpanded;
    }
}

function getTitleText(type) {
    switch (type) {
        case "client":
            return ["Home", "Owner"];
        case "provider":
            return ["Service", "Provider"];
        default:
            return ["Represent", "An EnterPrise"];
    }
}

export default Dashboard;