import React from "react";

import JobListingComponent from "../../jobs/components/joblistingscomponent";

const LookForWork = () => {
    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-center">
                    <div className="mt-6 w-[100%] 2xl:px-32 xl:px-20 lg:px-15">
                        <h1 className="text-black font-normal mb-10 self-start text-3xl mt-12">Look <span className="font-semibold">For Work</span></h1>
                        <JobListingComponent />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default LookForWork;