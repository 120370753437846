import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { domain } from "../../axios/adapter";
import fetch from "../../axios/manager";
import * as Loader from "../../dialogs/loader/loaderop";
import { listingFromMap, mainListingFromMap } from "../../global/methods/from-map";
import highlightString from "../../global/methods/substring-highlight";
import { hide } from "../../redux/slices/alertdialog";
import showToast from "../loader/toast";

const SearchListing = ({ onSubmit }) => {
    const [search, setSearch] = useState("");
    const [listings, setListings] = useState({});
    const [mainListings, setMainListings] = useState([]);
    const [searchListings, setSearchListings] = useState({});
    const [searchMainListings, setSearchMainListings] = useState([]);
    const dispatcher = useDispatch();

    const fetchListings = async () => {
        Loader.show();

        const [response, error] = await fetch({
            requestType: "get",
            route: "listings/listing/all",
            params: {
                sort: "DESC",
            },
            cacheKey: "listing-all",
            cacheOptions: "fromCache",
        });

        if (error != null) {
            showToast(error);
            return Loader.hide();
        }

        Loader.hide();

        const mlistings = {};
        const mMainListings = [];

        const mainListingData = response.res;

        for (let i = 0; i < mainListingData.length; i++) {
            const mainListing = mainListingFromMap(mainListingData[i]);

            mMainListings.push(mainListing);

            const listingData = mainListingData[i].listings;

            if (listingData == null) continue;

            const list = [];

            for (let k = 0; k < listingData.length; k++) {
                const listing = listingFromMap(listingData[k]);

                list.push(listing);
            }

            list.sort((l1, l2) => l1.type.localeCompare(l2.type));

            mlistings[mainListing.uuid] = list;
        }

        mMainListings.sort((m1, m2) => m1.name.localeCompare(m2.name));

        setListings(mlistings);
        setMainListings(mMainListings);
    };

    const onSearch = (value) => {
        const newSearchListings = {};
        const newSearchMainListings = [];

        mainListings.forEach((ml) => {
            if (ml.name.toLowerCase().includes(value.toLowerCase())) {
                newSearchMainListings.push(ml);
                newSearchListings[ml.uuid] = listings[ml.uuid];
            }
        });

        if (newSearchMainListings.length === 0) {
            const lkeys = Object.keys(listings);
            let xyz = [];

            lkeys.forEach((muui) => {
                listings[muui].forEach((list) => {
                    if (list.type.toLowerCase().includes(value.toLowerCase())) {
                        if (!newSearchListings[muui]) {
                            newSearchListings[muui] = [];
                        }
                        newSearchListings[muui].push(list);
                        xyz.push(muui);
                    }
                });
            });

            xyz = [...new Set(xyz)];
            xyz.forEach((x) => {
                newSearchMainListings.push(getMainCatFromUuid(x));
            });
        }

        setSearchListings(newSearchListings);
        setSearchMainListings(newSearchMainListings);
    };

    const getMainCatFromUuid = (uuid) => {
        const mainListing = mainListings.find((ml) => ml.uuid === uuid);

        return mainListing || mainListings[0];
    };

    useEffect(() => {
        fetchListings();
    }, []);

    useEffect(() => {
        if (search.length == 0) return;

        onSearch(search);
    }, [search]);

    return <div className="flex flex-col justify-between items-center py-7 px-10 w-[700px]">
        <input className="bg-textfield-back border-textfield-stroke border w-[100%] rounded-[10px] h-12 px-5 outline-none mb-5"
            onChange={(value) => setSearch(value.currentTarget.value)} value={search}
            size="h-12" type="text" name="search" placeholder="Search" />
        <div className="flex flex-col gap-1 w-full">
            {(search.length == 0 ? mainListings : searchMainListings).map(mainListing => {
                const mlistings = search.length == 0 ? listings : searchListings;

                return <div key={mainListing.uuid} className="flex flex-col gap-1">
                    <div className="flex border-card-border flex-row p-1 h-10 w-[100%] rounded-[8px] border gap-3 items-center hover:border-primary transition-all duration-200 ease-in-out cursor-pointer">
                        <img className="h-[100%] rounded-[5px] aspect-video object-cover" src={`${domain}/static/${mainListing.image}`} />
                        {highlightString({ strTarget: mainListing.name.replace(/ /g, '\u00A0'), subStr: search, styles: "text-black font-medium line-clamp-1 inline-block" })}
                    </div>

                    {(mlistings[mainListing.uuid] ?? []).map(listing => <div key={listing.uuid} onClick={() => {
                        onSubmit(listing);

                        setTimeout(() => {

                            dispatcher(hide());
                        }, 100);
                    }} className="flex border-card-border flex-row p-1 px-4 h-10 w-[100%] rounded-[8px] border gap-3 items-center hover:border-primary transition-all duration-200 ease-in-out cursor-pointer">
                        {highlightString({ strTarget: listing.type.replace(/ /g, '\u00A0'), subStr: search, styles: "text-black font-medium line-clamp-1 inline-block" })}
                    </div>)}
                </div>;
            })}
        </div>
    </div>;
};
export default SearchListing;