import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Cache from "../../../cache/cachemanager";
import BasicDialog from "../../../dialogs/alertdialog/basic_dialog";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import * as AlertDialog from "../../../redux/slices/alertdialog";
import SubscriptionCard from "../widgets/subscriptionCard";

const SubscriptionInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const { label, subscription, dontell } = location.state;

    const userSlice = useSelector(state => state.userSlice);
    const currentSubscription = userSlice.profileCompletion.subscription;

    function onUpgrade() {
        console.log("hii");
    }

    function onCancel() {
        dispatcher(AlertDialog.show({
            child: <BasicDialog title="Cancel Subscription"
                description={<div className="flex flex-col gap-1">
                    <h1>{`You are about to cancel your subscription to ${currentSubscription.name}.`}</h1>
                    <h1>Please review the following details.</h1>
                    <ul>
                        <li>{`Your current plan (${subscription.name}) will be cancelled immediately.`}</li>
                        <li>Any unused time on your current plan will not be refunded or prorated.</li>
                    </ul>
                    <h1 className="font-semibold">Are you sure you want to proceed with this change?</h1>
                </div>} positiveButtonText="YES, CANCEL" negativeButtonText="BACK" onAgree={cancelSubscription} />
        }));
    }

    async function cancelSubscription() {
        Loader.show();

        const [, error, status] = await fetch({
            route: "payments/auth/subscription/cancel",
            requestType: "delete",
        });

        if (error != null) {
            if (status == 401) {
                await Cache.removeAll();

                navigate("/", { replace: true });
                window.location.reload();
                Loader.hide();
                return;
            }

            showToast(error);
            Loader.hide();
            return;
        }

        await Cache.removeAll();

        navigate("/", { replace: true });
        window.location.reload();
        Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 mb-10 items-center justify-start">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20">
                        <h1 className="text-black font-normal mb-10 text-3xl mt-12">
                            {label}
                        </h1>
                        <SubscriptionCard onBuy={(dontell ?? false) ? onUpgrade : null} subscription={subscription} onUpgrade={() => {
                            navigate("/SubcriptionPickScreen", { state: { upgrade: true } });
                        }} onCancel={onCancel} />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default SubscriptionInfo;