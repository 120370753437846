export function mainListingFromMap(data) {
    return {
        uuid: data.uuid,
        name: data.name,
        image: data.image,
    };
}

export function listingFromMap(data) {
    return {
        uuid: data.uuid,
        type: data.type,
    };
}