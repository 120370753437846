import React from 'react';
import { Link } from "react-router-dom";

import { ReactComponent as FacebookIcon } from '../../assets/icons/homescreen/facebbok-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/homescreen/google-icon.svg';
import { ReactComponent as LinkdinIcon } from '../../assets/icons/homescreen/linkdin-icon.svg';
import locationIcon from '../../assets/icons/homescreen/location-icon.svg';
import PhoneIcon from '../../assets/icons/homescreen/phone-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/homescreen/twitter-icon.svg';

export default function Footer() {
    return (
        <>
            <footer className='border-t-[10px] border-lightGreen bg-foooterBlack'>
                <div className="container mx-auto xl:py-[120px] md:py-[80px] sm:[60px] py-[50px]">
                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1  sm:gap-[120px] gap-[30px]">
                        <div className="">
                            <h4 className='text-lg font-medium text-white uppercase mb-2'>ABOUT COMPANY</h4>
                            <p className='text-sm font-normal text-white opacity-80'>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using.</p>
                            <button type="button" className="text-white relative rounded-[12px] primary-gradient transition ease-in-out bg-blue-500 min-w-[160px] h-[46px] p-1 uppercase mt-7">
                                Read more
                            </button>
                        </div>

                        <div className='flex lg:justify-end sm:justify-start line-left'>
                            <div className='xl:w-2/3'>
                                <h4 className='text-lg text-white uppercase mb-2'>COMPANY</h4>
                                <ul className='pl-0'>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Contact Us</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">about us</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Careers</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className='flex lg:justify-end sm:justify-start line-left line-left-lg-none'>
                            <div className='xl:w-2/3'>
                                <ul className='pl-0'>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">All Services</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Designs</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Reviews</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Blogs</Link></li>
                                    <li className='py-2'><Link to={'/'} className="hover:opacity-100 text-white opacity-80 capitalize text-base font-light active" aria-current="page">Articles</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className='flex sm:justify-end line-left'>
                            <div className='xl:w-2/3'>
                                <h4 className='text-lg text-white capitalize mb-2'>Connect with Us</h4>
                                <ul className='pl-0'>
                                    <li className='py-2'><Link to={'tel:98801 00910'} className="flex items-start gap-2 text-white capitalize text-base font-light active" aria-current="page"><img src={PhoneIcon} alt='phone icon' /><span>+1 12345 67890</span></Link></li>
                                    <li className='py-2'><Link to={'/'} className="flex items-start gap-2 text-white capitalize text-base font-light active" aria-current="page"><img src={locationIcon} alt='location icon' /><span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span></Link></li>
                                </ul>
                                <div className="flex justify-between items-center my-4">
                                    <Link to={'/'} className='opacity-30 hover:opacity-100'><TwitterIcon /></Link>
                                    <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                    <Link to={'/'} className='opacity-30 hover:opacity-100'><FacebookIcon /></Link>
                                    <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                    <Link to={'/'} className='opacity-30 hover:opacity-100'><LinkdinIcon /></Link>
                                    <span className='bg-[#3F3F3F] h-[20px] w-[1px] block'></span>
                                    <Link to={'/'} className='opacity-30 hover:opacity-100'><GoogleIcon /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}