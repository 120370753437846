import React from "react";
import { useLocation } from "react-router-dom";

import StaggeredListView from "../../profile/components/staggered_listview";

const CompletedGigsListScreen = () => {
    const location = useLocation();
    const state = location.state;
    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <h1 className="text-black font-semibold mb-10 text-3xl mt-12">Completed Jobs</h1>

                        <StaggeredListView className="mb-8" columns={2} horizontalGap="gap-2" verticalGap="gap-2" items={state.items} />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default CompletedGigsListScreen;