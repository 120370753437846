import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Enterprise } from "../../../assets/icons/homescreen/enterprise.svg";
import { ReactComponent as Homeowner } from "../../../assets/icons/homescreen/homeowner.svg";
import { ReactComponent as Provider } from "../../../assets/icons/homescreen/provider.svg";
import fetch from "../../../axios/manager";
import * as Cache from "../../../cache/cachemanager";
import TermAndServiceDialog from "../../../dialogs/alertdialog/termsandservice";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import * as AlertDialog from "../../../redux/slices/alertdialog";
import { updateUser } from "../../../redux/slices/user";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const PickUserTypeScreen = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { email } = location.state;

    const [pickedItem, setPickedItem] = useState(null);

    const items = [
        {
            "label": "Commercial",
            "subtitle": "I represent a company",
            "type": "enterprise",
            "icon": Enterprise,
        },
        {
            "label": "Residential",
            "subtitle": "I am a home owner",
            "type": "client",
            "icon": Homeowner,
        },
        {
            "label": "I am a Pro",
            "subtitle": "I provide Professional Services",
            "type": "provider",
            "icon": Provider,
        },
    ];

    const onClicked = () => {
        if (pickedItem == null) {
            return showToast("Nothing selected");
        }

        dispatcher(updateUser({ userType: pickedItem }));

        dispatcher(AlertDialog.show({ child: <TermAndServiceDialog type={pickedItem} onAgree={() => onNext()} /> }));
    };

    const onNext = async () => {
        Loader.show();

        const [userData, userError] = await fetch({
            route: "/users",
            requestType: "post",
            body: {
                email: email,
                userType: pickedItem,
            },
            cacheKey: "userData",
        });

        Loader.hide();

        if (userError != null) {
            return showToast(userError);
        }

        await Cache.set("userData", userData);
        dispatcher(updateUser(userData));

        navigate("/ProfileEdit", {
            state: { firstTime: true },
        });
    };

    return <div className="flex flex-col w-[100%] items-center">
        <div className="flex flex-col my-10 items-center justify-start gap-4">
            {items.map((item) =>
                <div onClick={() => setPickedItem(item.type)} key={item.label}
                    className={`flex flex-row transition-all cursor-pointer hover:border-primary h-32 w-[500px] rounded-primary border-[#EBEBEB] items-center justify-start gap-2 bg-[#FAFAFA] duration-200 ease-in-out border ${item.type == pickedItem && "border-primary border-2"}`}>
                    <item.icon className="w-[20%]" />
                    <div className="flex-grow flex flex-col">
                        <h1>{item.label}</h1>
                        <h4 className="text-sm">{item.subtitle}</h4>
                    </div>
                </div>
            )}
        </div>

        <h4 className="mb-2 text-xs">By clicking Next, I state that I have read and agreed to the Terms of Services</h4>
        <OutlinedButton onClicked={() => onClicked()} label="Agree & Next" extracss="self-center primary-gradient line-clamp-1 h-11 w-[200px] border-none text-white" />
    </div>;
};

export default PickUserTypeScreen;