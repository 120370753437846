import { useEffect } from 'react';

export default function usePageVisibility(onBlurCallback, onFocusCallback) {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                onBlurCallback();
            } else {
                onFocusCallback();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [onBlurCallback, onFocusCallback]);
}