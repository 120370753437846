import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Cache from "../../../cache/cachemanager";
import AlertDialogSsnBody from "../../../dialogs/alertdialog/ssn_dialog";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import usePageVisibility from "../../../global/hooks/page_visibility";
import goTo from "../../../global/methods/goto";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateCompletion } from "../../../redux/slices/user";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const ProviderVerificationScreen = () => {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const userSlice = useSelector((state) => state.userSlice);

    const profileCompletion = userSlice.profileCompletion;
    const user = userSlice.user;

    const [activeStep, setActiveStep] = useState(0);

    usePageVisibility(onBlur, onFocus);

    const steps = [
        {
            label: "Verification Charge $50",
        },
        {
            label: "Checkr Verification",
        }
    ];


    function onBlur() {
        console.log("going background");
    }

    function onFocus() {
        refresh();
    }

    async function refresh() {
        Loader.show();

        const [profileCompletionData, error, status] = await fetch({
            route: `users/${user.uuid}/profileCompletion`,
            requestType: "get",
        });

        if (error != null) {
            Loader.hide();

            if (status == 401) {
                navigate("/", { replace: true });
                return;
            }

            showToast(error);
            return;
        }

        dispatcher(updateCompletion(profileCompletionData.res));

        await refreshCheckr();

        Loader.hide();
    }

    useEffect(() => {
        if ((profileCompletion?.verificationPayment ?? -1) == 1 &&
            (profileCompletion?.checkrVerification ?? 0) == 1 &&
            (profileCompletion?.userVerification ?? -1) == 1 && (profileCompletion?.subscriptionId ?? -1) == -1) {
            navigate("/SubcriptionPickScreen");
            return;
        }

        refreshCheckr();

        finishIdvForProvider();
    }, []);

    async function finishIdvForProvider() {
        Loader.show();

        const [, error, status] = await fetch({
            route: `users/profileCompletion/${user.uuid}`,
            requestType: "put",
            body: {
                userVerification: 1,
            }
        });

        Loader.hide();

        if (error != null) {
            if (status == 401) {
                Cache.removeAll();

                navigate("/", { replace: true });
                window.location.reload();
                return;
            }
        }

        dispatcher(updateCompletion({
            userVerification: 1,
        }));
    }

    async function refreshCheckr() {
        if ((profileCompletion?.userVerification) == 1 && (profileCompletion?.checkrVerification ?? 0) != 1) {
            const [checkrData, checkrError, status] = await fetch({
                route: `users/checkr-status/${user.uuid}`,
                requestType: "get",
            });

            if (checkrError != null) {
                if (status == 401) {
                    Cache.removeAll();

                    navigate("/", { replace: "/" });
                    window.location.reload();
                    return;
                }

                console.log(checkrError);
                return;
            }

            if (checkrData.status == "pending" || checkrData.status == "complete") {
                dispatcher(updateCompletion({
                    checkrStatus: 2,
                }));
            }
            else if (checkrData.status == "consider") {
                dispatcher(updateCompletion({
                    checkrStatus: 3,
                }));
            }
            else if (checkrData.status == "rejected") {
                dispatcher(updateCompletion({
                    checkrStatus: -1,
                }));
            }
            else if (checkrData.status == "clear") {
                dispatcher(updateCompletion({
                    checkrStatus: 1,
                    checkrVerification: 1,
                }));
            }
            else {
                updateCompletion({
                    checkrStatus: 0,
                });
            }
        }
        else if ((profileCompletion?.checkrVerification ?? 0) == 1) {
            dispatcher(updateCompletion({
                checkrStatus: 1,
            }));
        }

        setActiveStep(getActiveStep());
    }

    function onButtonClicked() {
        if ((profileCompletion?.verificationPayment ?? 0) != 1) {
            goTo(`https://payments.onceusa.com/${user.uuid}`);
        }
        else if ((profileCompletion?.checkrVerification ?? 0) == 1) {
            navigate("/SubcriptionPickScreen");
            return;
        }
        else {
            if ((profileCompletion?.checkrStatus ?? 0) == 0) {
                startCheckr();
            }
        }
    }

    async function startCheckr() {
        const values = await getValues();

        if (values == null) return;

        console.log(values);

        Loader.show();

        const [invitationData, error, status] = await fetch({
            route: "users/create-checkr-invitation",
            requestType: "post",
            body: {
                uuid: user.uuid,
                ssn: values.ssn,
                "work-state": values.state,
                "work-city": values.city,
            }
        });

        Loader.hide();

        if (error != null) {
            if (status == 401) {
                Cache.removeAll();

                navigate("/", { replace: true });
                window.location.reload();
                return;
            }

            showToast(error);
            return;
        }

        const url = invitationData.invitationUrl;

        goTo(url);
    }

    async function getValues() {
        return new Promise((resolve) => {
            dispatcher(Alertdialog.show({ child: <AlertDialogSsnBody onSubmit={resolve} /> }));
        });
    }

    function getActiveStep() {
        if ((profileCompletion?.checkrVerification ?? 0) == 1) {
            return 2;
        }

        if ((profileCompletion?.verificationPayment ?? -1) == 1) {
            return 1;
        }

        return 0;
    }

    function getButtonText() {
        if ((profileCompletion?.verificationPayment ?? -1) != 1) {
            return "Pay verification fee";
        }

        if ((profileCompletion?.checkrVerification ?? 0) != 1) {
            if ((profileCompletion?.checkrStatus ?? 0) == 0) {
                return "Start Checkr Verification";
            }

            return getCheckrStatus();
        }

        else {
            return "Buy Subscription";
        }
    }

    function getCheckrStatus() {
        const checkrStatus = profileCompletion?.checkrStatus ?? 0;

        if (checkrStatus == 2) {
            return "Pending";
        }
        else if (checkrStatus == 1) {
            return "complete";
        }
        else if (checkrStatus == 3) {
            return "consider";
        }
        else if (checkrStatus == -1) {
            return "rejected";
        }
        else {
            return "Start Checkr Verification";
        }
    }

    return <div className="flex flex-col w-[100%] justify-center h-[80vh] items-center">
        <div className="flex flex-col mx-10 w-[400px] p-10 items-center justify-center border-[#EBEBEB] border rounded-primary bg-[#FAFAFA] gap-6 mb-10">
            <Stepper activeStep={activeStep}
                connectorStyleConfig={{
                    completedColor: "#099F44",
                    activeColor: "#099F44"
                }}
                styleConfig={{
                    activeBgColor: "#099F44",
                    completedBgColor: "#099F44",
                }} connectorStateColors={true}>
                {steps.map((item) => {
                    return <Step key={item.label} label={item.label} />;
                })}
            </Stepper>

            <OutlinedButton onClicked={() => onButtonClicked()} label={getButtonText()} extracss="primary-gradient text-white border-none w-[100%] h-11" />
        </div>
    </div>;
};

export default ProviderVerificationScreen;