import React from "react";
import { useSelector } from "react-redux";

import CardItem from "../components/carditem";
import TransactionItem from "../components/transactionitem";

const WalletScreen = () => {
    const walletSlice = useSelector((state) => state.walletSlice);

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-16 mt-16 items-center justify-center">
                    <div className="flex flex-col self-start">
                        <h1 className="text-3xl font-semibold text-darkGray text-start mb-5">Wallet</h1>

                        <h4 className="text-darkGray text-opacity-50 text-md">Available</h4>
                        <h1 className="text-3xl text-darkGray font-semibold text-start mb-5"><span className="px-1">$</span> {walletSlice.balance}</h1>

                        <h2 className="text-lg text-darkGray font-semibold">My Cards</h2>
                    </div>

                    <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-5 self-stretch mt-4">
                        {walletSlice.cards.map((item) => <CardItem key={item.uuid} item={item} />)}
                    </div>

                    <div className="flex flex-row justify-between self-stretch items-center mb-0 my-9">
                        <h2 className="text-lg text-darkGray font-semibold self-stretch text-start ">Transactions</h2>
                        <div className="bg-[#DFDFDF] border-darkGray border rounded-primary h-min p-2 px-4 opacity-50 cursor-pointer hover:bg-white transition-all ease-in-out duration-200">See All</div>
                    </div>

                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 self-stretch grid-cols-1 place-items-start gap-5 my-6">
                        {walletSlice.transactions.map((item) =>
                            <TransactionItem key={item.uuid} item={item} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default WalletScreen;