import { ErrorMessage, Field } from "formik";
import React from "react";

const TextField = ({ name, type, placeholder, size, label, extraCss, child, contentPadding, error, back, borderProp, padding, ...props }) => {
    const border = error ? "border-red" : borderProp ?? "border-textfield-stroke";
    return <div className={`${extraCss} ${size}`}>
        {label != null && <label htmlFor={`${name}-field`}>{label}</label>}
        <Field id={`${name}-field`} className={`${back ?? 'bg-textfield-back'} ${border} border w-[100%] rounded-[10px] h-[100%] ${(padding ?? true) ? 'px-5' : ''} ${contentPadding} outline-none`} type={type} name={name} placeholder={placeholder} {...props}></Field>
        <ErrorMessage component="p" className="text-xs text-red" name={name} />
        {child}
    </div>;
};

export default TextField;