import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";
import { addItem } from "../../../redux/slices/joblistings";
import { setProgress } from "../../../redux/slices/toploadingbar";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const MyServiceOffering = () => {
    const services = useSelector((state) => Object.values(state.jobListingSlice.services));
    const profile = useSelector((state) => state.userSlice.profile);

    const dispatcher = useDispatch();

    const fetchServices = async (offset, limit) => {
        if (offset === 0 && services.length !== 0) {
            return services;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `services/profile/${profile.uuid}/all`,
            requestType: "get",
            cacheKey: `services-${profile.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const servicesData = result.res;

        for (let i = 0; i < servicesData.length; i++) {
            dispatcher(addItem({ type: "services", item: servicesData[i] }));
        }

        dispatcher(setProgress(100));
        Loader.hide();

        return servicesData;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-center">
                    <div className="flex flex-row justify-between w-[100%] h-14 items-center text-3xl mt-12 mb-3">
                        <h1 className="text-black font-light col-span-full ">My <span className="font-semibold">Service Offering</span></h1>
                        <Link className="h-[100%]" to="/AddService">
                            <OutlinedButton onClicked={() => { }} extracss="bg-white h-[100%] border-primary text-lg text-primary font-semibold text-md hover:text-white hover:border-white hover:bg-primary w-[150px] text-center"
                                label="+ Add Service" />
                        </Link>
                    </div>
                    <div className="w-full">
                        <PaginatedScrollView onNotFound="No services found.Time to get creative!" className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 place-items-start gap-5 mt-6" pageRequest={fetchServices}
                            child={(item) => (
                                <Link to="/serviceInfo" key={item.uuid} state={{
                                    service: item,
                                    isItMe: true,
                                }}>
                                    <div className="bg-white cursor-pointer relative flex mx-4 justify-center items-end h-[200px] w-[200px] rounded-primary overflow-hidden"
                                        key={item.uuid}>
                                        <img loading="lazy" src={`${domain}/static/${item.images[0]}`} alt="service" className="absolute object-cover text-center w-[100%] h-[100%]" />
                                        <div className="w-[100%] h-[100%] absolute" style={{ background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)" }} />
                                        <div className="flex flex-col items-center z-[1] mb-3">
                                            <h4 className="text-white font-bold text-xl capitalize">{item.title}</h4>
                                        </div>
                                    </div>
                                </Link>
                            )} />
                    </div>
                </div>
            </div>
        </section>
    </main >;
};

export default MyServiceOffering;