import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import { listingFromMap, mainListingFromMap } from "../../../global/methods/from-map";
import { setProgress } from "../../../redux/slices/toploadingbar";

const FindAProfessional = () => {
    const [listings, setListings] = useState({});
    const [mainListings, setMainListings] = useState([]);
    const dispatcher = useDispatch();

    useEffect(() => {
        fetchListings();
    }, []);

    const fetchListings = async () => {
        Loader.show();

        const [response, error] = await fetch({
            requestType: "get",
            route: "listings/listing/all",
            params: {
                sort: "DESC",
            },
            cacheKey: "listing-all",
            cacheOptions: "fromCache",
        });

        if (error != null) {
            return onError(error);
        }

        Loader.hide();

        const mlistings = {};
        const mMainListings = [];

        const mainListingData = response.res;

        for (let i = 0; i < mainListingData.length; i++) {
            const mainListing = mainListingFromMap(mainListingData[i]);

            mMainListings.push(mainListing);

            const listingData = mainListingData[i].listings;

            if (listingData == null) continue;

            const list = [];

            for (let k = 0; k < listingData.length; k++) {
                const listing = listingFromMap(listingData[k]);

                list.push(listing);
            }

            list.sort((l1, l2) => l1.type.localeCompare(l2.type));

            mlistings[mainListing.uuid] = list;
        }

        mMainListings.sort((m1, m2) => m1.name.localeCompare(m2.name));

        setListings(mlistings);
        setMainListings(mMainListings);
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col px-5 mb-10 items-center justify-center w-[100%]">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 place-items-start gap-5 mt-6">
                        <h1 className="text-black font-light col-span-full mx-4 mb-3 self-start text-3xl mt-12">Find a <span className="font-semibold">Professional</span></h1>
                        {mainListings.map(mainListing => <Link to="/ListingsScreen" key={mainListing.uuid} state={{
                            mainListing: mainListing,
                            listings: listings[mainListing.uuid]
                        }} className="w-full">
                            <div className="bg-white cursor-pointer relative flex mx-4 justify-center items-end w-full aspect-video h-auto rounded-primary overflow-hidden" key={mainListing.uuid}>
                                <img loading="lazy" src={`${domain}/static/${mainListing.image}`} alt="service" className="absolute object-cover text-center w-[100%] h-[100%]" />
                                <div className="w-[100%] h-[100%] absolute" style={{ background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)" }} />
                                <div className="flex flex-col items-center z-[1] mb-3">
                                    <h4 className="text-white font-bold text-xl capitalize text-center">{mainListing.name}</h4>
                                </div>
                            </div>
                        </Link>)}
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default FindAProfessional;