import React from 'react';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TabBar from './tabbar';
import ChatRoomItem from '../../userside/chats/components/chatroomitem';

const PaginatedScrollView = ({ limit, pageRequest, child, className, topChild, bottomChild, style, innerWidgets, onNotFound }) => {
    const [items, setItems] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        fetchNewStuff();
    }, []);

    const fetchNewStuff = async () => {
        const newItems = await pageRequest(offset, limit);

        setOffset((state) => state + newItems.length);

        const isLastPage = newItems.length < limit;

        if (offset == 0) {
            setItems(newItems);
        }
        else {
            setItems((state) => [
                ...state,
                ...newItems
            ]);
        }

        if (isLastPage) {
            setHasMore(false);
        }
    };

    const onRefresh = () => {
        setOffset(0);
        setHasMore(true);
        setItems([]);
    };

    return <>
        {items?.length == 0 && <div className='w-full h-[400px] flex items-center justify-center'>
            <h1>{onNotFound}</h1>
        </div>}
        <InfiniteScroll
            className={innerWidgets != null && className}
            pullDownToRefresh
            refreshFunction={onRefresh}
            dataLength={items?.length ?? 0}
            hasMore={hasMore}
            scrollableTarget="root"
            next={() => fetchNewStuff()}
        >
            {innerWidgets == null
                ? <div className={className} style={style}>
                    {topChild}
                    {(items ?? []).map((item) => child(item))}
                    {bottomChild}
                </div>
                : innerWidgets}
        </InfiniteScroll>
    </>;
};

export const PaginatedScrollViewDistance = ({ limit, pageRequest, child, className, distance, onNotFound }) => {
    const [items, setItems] = useState({});
    const [hasMore, setHasMore] = useState({ "-1": true });
    const [offset, setOffset] = useState({ "-1": 0 });

    useEffect(() => {
        if (hasMore[distance] == null) {
            setHasMore(prevHasMore => ({ ...prevHasMore, [distance]: true }));
        }
        if (offset[distance] == null) {
            setOffset(prevOffset => ({ ...prevOffset, [distance]: 0 }));
        }
        fetchNewStuff();
    }, [distance]);

    const fetchNewStuff = async () => {
        const newItems = await pageRequest(offset[distance], limit[distance]);

        setOffset(prevOffset => ({ ...prevOffset, [distance]: prevOffset[distance] + newItems.length }));

        const isLastPage = newItems.length < limit;


        if (offset[distance] == 0) {
            setItems(prevItems => ({
                ...prevItems,
                [distance]: newItems,
            }));
        }
        else {
            setItems(prevItems => ({
                ...prevItems,
                [distance]: [
                    ...(prevItems[distance] ?? []),
                    ...newItems
                ]
            }));
        }
        if (isLastPage) {
            setHasMore(prevHasMore => ({ ...prevHasMore, [distance]: false }));
        }
    };

    const onRefresh = () => {
        setOffset(prevOffset => ({ ...prevOffset, [distance]: 0 }));

        setHasMore(prevHasMore => ({ ...prevHasMore, [distance]: true }));

        setItems(prevItems => ({ ...prevItems, [distance]: [] }));
    };

    return <>
        {items[distance]?.length == 0 && <div className='w-full h-[400px] flex items-center justify-center'>
            <h1>{onNotFound}</h1>
        </div>}
        <InfiniteScroll
            pullDownToRefresh
            refreshFunction={onRefresh}
            dataLength={items[distance]?.length ?? 0}
            hasMore={hasMore[distance] ?? true}
            scrollableTarget="root"
            next={() => fetchNewStuff()}
        >
            <div className={className} >
                {(items[distance] ?? []).map((item) => child(item))}
            </div>
        </InfiniteScroll>
    </>;
};

export const PaginatedScrollViewChat = ({ limit, pageRequest, className, onNotFound, notifications }) => {
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const tabItems = ['Chat', 'Work chat'];
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        fetchNewStuff();
    }, []);

    const fetchNewStuff = async () => {
        const newItems = await pageRequest(offset, limit);

        setOffset((state) => state + newItems.length);

        const isLastPage = newItems.length < limit;

        if (offset == 0) {
            setItems(newItems);
        }
        else {
            setItems((state) => [
                ...state,
                ...newItems
            ]);
        }

        if (isLastPage) {
            setHasMore(false);
        }
    };

    const onRefresh = () => {
        setOffset(0);
        setHasMore(true);
        setItems([]);
    };

    return <div className="flex flex-col">
        <TabBar extraCss="h-12 mx-3 my-3" notifications={notifications}
            onChange={setCurrent} innerRounded='rounded-[10px]' outerRounded='rounded-primary'
            items={tabItems} width="w-[50%]" />
        {current == 0 && <>
            {items.length == 0 && <div className='w-full h-[400px] flex items-center justify-center'>
                <h1>{onNotFound}</h1>
            </div>}
            <InfiniteScroll
                className={className}
                pullDownToRefresh
                refreshFunction={onRefresh}
                dataLength={items.length}
                hasMore={hasMore}
                next={() => fetchNewStuff()}
            >
                <div className={className}>
                    {items.map((item) => <ChatRoomItem key={item.uuid} chatroomUUID={item.uuid} type="chat" />)}
                </div>
            </InfiniteScroll>
        </>}
        {current == 1 && <>
            {items.length == 0 && <div className='w-full h-[400px] flex items-center justify-center'>
                <h1>{onNotFound}</h1>
            </div>}
            <InfiniteScroll
                className={className}
                pullDownToRefresh
                refreshFunction={onRefresh}
                dataLength={items.length}
                hasMore={hasMore}
                next={() => fetchNewStuff()}
            >
                <div className={className}>
                    {items.map((item) => <ChatRoomItem key={item.uuid} chatroomUUID={item.uuid} type="work" />)}
                </div>
            </InfiniteScroll>
        </>}
    </div>;
};

export default PaginatedScrollView;
