import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";
import TabBar from "../../../global/components/tabbar";
import { setProgress } from "../../../redux/slices/toploadingbar";
import RatingAndMessages from "../components/ratingAndMessages";
import StaggeredListView from "../components/staggered_listview";

const ProviderPublicProfile = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const tabItems = ['Completed', 'Service Offering'];

    const [current, setCurrent] = useState(0);

    const [completedGigs, setCompletedGigs] = useState([]);
    const [services, setServices] = useState([]);

    const state = location.state;
    const profile = state.profile;

    const fetchServices = async (offset, limit) => {
        if (offset === 0 && services.length !== 0) {
            return services;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `services/profile/${profile.uuid}/all`,
            requestType: "get",
            cacheKey: `services-${profile.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const servicesData = result.res;

        setServices((state) => [
            ...state,
            ...services,
        ]);

        dispatcher(setProgress(100));
        Loader.hide();

        return servicesData;
    };

    const fetchCompletedGigs = async (offset, limit) => {
        if (offset === 0 && completedGigs.length !== 0) {
            return completedGigs;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `completedGigs/all/${profile.uuid}`,
            requestType: "get",
            cacheKey: `completedGigs-${profile.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const completedGigsData = result.res;

        setCompletedGigs((state) => [
            ...state,
            ...completedGigsData,
        ]);

        dispatcher(setProgress(100));
        Loader.hide();

        return completedGigsData;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <h1 className="text-black font-semibold mb-10 text-3xl mt-12">Profile</h1>
                        <div className="flex flex-row justify-between w-[100%] flex-wrap gap-5">
                            <div className="flex flex-row gap-4 justify-start">
                                <div className="h-[120px] w-[120px] flex-shrink-0">
                                    <img src={`${domain}/static/${profile.profileImage}`} alt="profile"
                                        style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.1)" }} className="border-white h-full w-full aspect-square object-cover rounded-[50%] p-[3px]" />
                                </div>
                                <div className="flex flex-col gap-2 my-3 w-[60%]">
                                    <h1 className="font-semibold text-lg">{`${profile.firstName} ${profile.lastName}`}</h1>
                                    <div className="flex flex-row justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2">{`${profile.location}, ${profile.landmark}, ${profile.state}`}</h1>
                                    </div>
                                </div>
                            </div>

                            <RatingAndMessages messageLabel="Send a Message" onMessage={() => navigate("/Chatrooms")}
                                onReview={() => navigate("/ReviewListScreen", { state: { fetchUrl: `reviews/profile/${profile.uuid}/all` } })}
                                reviews={profile.reviews} reviewsCount={profile.reviewsCount} />
                        </div>
                        <TabBar current={current}
                            onChange={setCurrent} innerRounded='rounded-[10px]' outerRounded='rounded-primary'
                            items={tabItems} width={"w-[50%]"}
                            extraCss="my-5 xl:w-[500px] lg:w-[450px] w-[400px] h-14 mr-3" />

                        {current === 1 && <PaginatedScrollView onNotFound="No services found" className="grid xl:grid-cols-5 lg:grid-cols-4 grid-cols-3 place-items-start gap-5 my-6" pageRequest={fetchServices}
                            child={(item) => {
                                return <Link to="/serviceInfo" key={item.uuid} state={{ service: item, isItMe: false }}>
                                    <div className="bg-white cursor-pointer relative flex justify-center items-end h-[200px] w-[200px] rounded-primary overflow-hidden" key={item.uuid}>
                                        <img loading="lazy" src={`${domain}/static/${item.images[0]}`} alt="service" className="absolute object-cover text-center w-[100%] h-[100%]" />
                                        <div className="w-[100%] h-[100%] absolute" style={{ background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)" }} />
                                        <div className="flex flex-col items-center z-[1] mb-3">
                                            <h4 className="text-white font-bold text-xl capitalize">{item.title}</h4>
                                        </div>
                                    </div>
                                </Link>;
                            }} />}

                        {current === 0 &&
                            <StaggeredListView onNotFound="No completed jobs yet." className="mb-8" columns={2} horizontalGap="gap-2" verticalGap="gap-2" items={completedGigs} pageRequest={fetchCompletedGigs} />
                        }
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default ProviderPublicProfile;