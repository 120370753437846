import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as Cross } from "../../../assets/icons/dashboard/cross.svg";
import { ReactComponent as Star } from "../../../assets/icons/homescreen/star-fill.svg";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import OutlinedButton from "../../homepage/components/outlinedbutton";
import UploadContainer from "../components/uploadcontainer";

const AddReviewDialog = ({ onSubmit }) => {
    const dispatcher = useDispatch();

    const [review, setReview] = useState(0);
    const [images, setImages] = useState([]);
    const [blobs, setBlobs] = useState([]);

    const description = useRef();

    const onSubmitClicked = () => {
        if (images.length == 0 || review == 0) return;

        const obj = {
            review: review,
            images: blobs,
            description: description.current.value,
        };

        onSubmit(obj);

        dispatcher(Alertdialog.hide());
    };

    const onUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;

        input.onchange = (e) => {
            decodeImages(e.target.files);
        };

        input.click();
    };

    const decodeImages = (files) => {
        const myImages = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/')) {
                myImages.push(URL.createObjectURL(file));
            }
        }

        setImages([
            ...images ?? [],
            ...myImages,
        ]);

        setBlobs([
            ...blobs ?? [],
            ...files,
        ]);
    };

    const removeImage = (indexToRemove) => {
        const updatedImages = images.filter((_, index) => index !== indexToRemove);
        const updatedBlobs = blobs.filter((_, index) => index !== indexToRemove);
        setImages(updatedImages);
        setBlobs(updatedBlobs);
    };

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col items-center justify-start p-10">
                    <div className="w-[100%]">
                        <h1 className="text-black font-semibold mb-10 text-3xl">
                            + Add Review
                        </h1>
                        <div className="flex flex-col w-[400px] gap-4">
                            <div className="flex bg-[#F9F9F9] h-14 border border-[#DDDDDD] justify-center rounded-primary items-center cursor-pointer flex-row gap-4">
                                {Array.from({ length: 5 }).map((value, index) =>
                                    <div key={index} onClick={() => setReview(index + 1)}>
                                        <Star className="h-[25px] w-[25px]" style={index < review ? {} :
                                            { filter: "invert(76%) sepia(70%) saturate(17%) hue-rotate(358deg) brightness(184%) contrast(84%)" }} />
                                    </div>
                                )}
                            </div>
                            <textarea ref={description} className='border-textfield-stroke bg-textfield-back resize-y flex-grow border w-[100%] min-h-[150px] rounded-[10px] outline-none px-5 py-3'
                                name="fullDescription" placeholder="Description" />

                            <UploadContainer onClick={() => onUpload()} extraCss="h-[120px]" />
                            {(images != null && images.length !== 0) && <div className="flex flex-row flex-wrap gap-3 items-center justify-center">
                                {images.map((image, index) => {
                                    return (
                                        <div className="h-24 w-24 relative" key={image}>
                                            <div onClick={() => removeImage(index)} className="bg-white absolute right-1 top-1 cursor-pointer rounded-[50%] w-4 h-4 p-[1px] flex justify-center items-center">
                                                <Cross />
                                            </div>
                                            <img src={image} className="object-cover aspect-square rounded-primary" alt="picked" height="100%" width="100%" />
                                        </div>
                                    );
                                })}
                            </div>}

                            <OutlinedButton onClicked={() => onSubmitClicked()} label="Submit" extracss="primary-gradient h-11 text-white rounded-primary border-none" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default AddReviewDialog;