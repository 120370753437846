import dateFormat from "dateformat";
import React from "react";

import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";


const TransactionItem = ({ item }) => {
    return <div style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} className="flex flex-row w-[100%] bg-[#F9F9F9] gap-3 border-[#DDDDDD] border rounded-primary h-20 p-3 hover:bg-opacity-70 hover:border-primary transition-all ease-in-out duration-200 cursor-pointer">
        <div className="h-[100%] aspect-square self-stretch">
            <img className="object-contain rounded-[10px]" src={item.profileImage} alt="paid to" height="100%" width="100%" />
        </div>

        <div className="flex-grow items-stretch flex flex-col justify-center">
            <h1 className="text-darkGray font-semibold text-md">{item.paidBy}</h1>
            <div className="flex flex-row items-center gap-2">
                <Calender />
                <h1>{dateFormat(item.date, "mmmm d, yyyy 'at' h:mmtt")}</h1>
            </div>
        </div>

        <div className="flex flex-row text-primary gap-1 items-center self-center">
            <span className="text-2xl font-bold">+</span>
            <h1 className="font-bold text-xl mr-2">
                {item.amount}
            </h1>
        </div>
    </div>;
};

export default TransactionItem;