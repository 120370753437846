import dateFormat from "dateformat";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';

import { ReactComponent as Edit } from "../../../assets/icons/dashboard/edit-icon.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import Button from "../../../global/components/buttton";
import TabBar from "../../../global/components/tabbar";
import TextField from "../../../global/components/textfield";
import { addChat } from "../../../redux/slices/chatrooms";
import { setProgress } from "../../../redux/slices/toploadingbar";

const CreateEstimate = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { job, service, chatroomUUID, channelUUID } = location.state;

    const dispatcher = useDispatch();
    const item = job ?? service;

    const initialValues = {
        'dateTime': "",
        "price": "",
        "name": "",
        "description": ""
    };

    const tabItems = ['One Time', 'Milestone'];

    const [milestones, setMilestones] = useState([]);

    const [currentTab, setCurrentTab] = useState('One Time');

    const [editing, setEditing] = useState(null);

    const formSchema = Yup.object().shape({
        dateTime: Yup.string()
            .required(),
        price: Yup.string()
            .required().min(0),
        name: Yup.string()
            .required(),
        description: Yup.string()
            .required(),
    });

    const onSubmit = async (values) => {
        if (currentTab !== 'One Time') {
            values = {
                date: values.dateTime,
                milestones: milestones,
            };
        }
        else {
            values = {
                date: values.dateTime,
                milestones: [{
                    name: values.name,
                    price: values.price,
                    description: values.description,
                }],
            };
        }

        Loader.show();
        dispatcher(setProgress(40));

        const [estimateData, estimateError] = await fetch({
            route: "chats/auth/estimates/",
            requestType: "post",
            body: {
                jobUUID: job?.uuid,
                serviceUUID: service?.uuid,
                ...values,
                chatroomUUID: chatroomUUID,
                channelUUID: channelUUID,
                price: getPrice(values.milestones),
            }
        });

        dispatcher(setProgress(70));

        if (estimateError != null) {
            return onError(estimateError);
        }

        const [chat, chatError] = await fetch({
            route: "chats/auth/chats",
            requestType: "post",
            body: {
                readStatus: 0,
                chatType: "estimate",
                chatroomUUID: chatroomUUID,
                channelUUID: channelUUID,
                estimateUUID: estimateData.res.estimate.uuid,
            }
        });

        dispatcher(setProgress(100));
        Loader.hide();

        if (chatError != null) {
            return onError(chatError);
        }

        dispatcher(addChat({
            chatroomUUID: chatroomUUID,
            chat: {
                ...chat.res,
                estimate: estimateData.res,
            },
            channelUUID,
            last: true,
        }));

        navigate(-1);
    };

    const getPrice = (milestones) => {
        let price = 0;

        for (let i = 0; i < milestones.length; i++) {
            price += parseFloat(milestones[i].price);
        }

        return price;
    };

    const onError = (error) => {
        showToast(error);
        Loader.hide();
        dispatcher(setProgress(100));
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: formSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    const onMilestoneSave = async () => {
        {
            const errors = await formik.validateForm();

            if (errors.dateTime != null || errors.description != null || errors.name != null || errors.price != null) return;
            setMilestones((state) => {
                const obj = {
                    "name": formik.values.name,
                    "price": formik.values.price,
                    "description": formik.values.description,
                };
                if (editing == null) {
                    state.push(obj);
                }
                else {
                    state[editing] = obj;
                    setEditing(null);
                }


                formik.setFieldValue('name', '');
                formik.setFieldValue('price', '');
                formik.setFieldValue('description', '');

                return state;
            });
        }
    };

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <h1 className="text-black font-semibold text-3xl mt-12">Estimate</h1>
                        <h1 className="font-medium text-2xl my-7">Estimate Summary</h1>

                        <h1 className="text-black font-medium text-lg mb-3">{item.title}</h1>
                        <p className="text-darkGray opacity-50 w-[80%]">{item.fullDescription}</p>
                        <h1 className="text-darkGray font-semibold mt-5">Images:</h1>

                        <div className="2xl:w-[60%] w-[65%] max-md:w-[75%] gap-4 flex flex-row flex-wrap justify-start pt-3">
                            {item.images.map((image) =>
                                <img key={image} className="rounded-primary aspect-square object-cover w-[130px] h-[130px]" src={`${domain}/static/${image}`} alt="job" />
                            )}
                        </div>

                        <div className="w-[100%] h-[8px] bg-darkGray bg-opacity-5 my-5" />

                        <h1 className="font-medium text-2xl">My Estimate</h1>

                        <FormikProvider value={formik}>
                            <form onSubmit={(e) => {
                                if (currentTab === 'Milestone' && milestones.length > 1) {
                                    setEditing(milestones.length - 1);
                                    const item = milestones[milestones.length - 1];

                                    formik.setFieldValue('name', item.name);
                                    formik.setFieldValue('price', item.price);
                                    formik.setFieldValue('description', item.description);

                                    formik.handleSubmit(e);
                                }
                                else {
                                    formik.handleSubmit(e);
                                }
                            }}>
                                <div className="px-4 py-4 mb-10 flex flex-col w-[600px] items-stretch gap-3">
                                    <TextField error={formik.errors.dateTime != null && formik.touched} style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} borderProp="border-[#DDDDDD]" back="bg-[#F9F9F9]" min={dateFormat(Date.now(), "yyyy-mm-dd'T'HH:MM")} size="h-12" type="datetime-local" name="dateTime" placeholder="Preferred Start Date & Time" />

                                    <div className="bg-[#F9F9F9] border-[#DDDDDD] rounded-[10px] border p-2 flex flex-col gap-2">
                                        <TabBar current={currentTab} onChange={(value) => { setCurrentTab(value); }} items={tabItems} outerRounded='rounded-[10px]' innerRounded='rounded-[8px]' width="w-[50%]" extraCss="w-[100%] h-12 mr-3" innerHeight="h-10" />
                                        {currentTab === 'One Time' ?
                                            <div className="flex flex-col gap-2">
                                                <TextField error={formik.errors.name != null && formik.touched} style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} borderProp="border-[#DDDDDD]" back="bg-[#F9F9F9]" size="h-12" type="text" name="name" placeholder="Name" />
                                                <TextField error={formik.errors.price != null && formik.touched} style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} borderProp="border-[#DDDDDD]" back="bg-[#F9F9F9]" size="h-12" type="number" name="price" placeholder="Price" />
                                                <textarea style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} onChange={formik.handleChange} value={formik.values.description}
                                                    className={`${(formik.errors.description != null && formik.touched) ? "border-red" : "border-[#DDDDDD]"} bg-[#F9F9F9] resize-y border w-[100%] rounded-[10px] outline-none px-5 py-3`}
                                                    name="description" placeholder="Description" />
                                            </div>
                                            :
                                            <div className="flex flex-col gap-2">
                                                {milestones.map((item, index) =>
                                                    <div key={item.uuid} onClick={() => {
                                                        setEditing(index);

                                                        formik.setFieldValue('name', item.name);
                                                        formik.setFieldValue('price', item.price);
                                                        formik.setFieldValue('description', item.description);
                                                    }} className="rounded-primary flex flex-row justify-between px-5 items-center border-primary cursor-pointer border h-12 bg-card-border">
                                                        <h1>{item.name}</h1>
                                                        <Edit />
                                                    </div>
                                                )}
                                                <TextField error={formik.errors.name != null && formik.touched} style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} borderProp="border-[#DDDDDD]" back="bg-[#F9F9F9]" type="text" size="h-12" name={`name`} placeholder="Milestone Name" />
                                                <TextField error={formik.errors.price != null && formik.touched} style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} borderProp="border-[#DDDDDD]" back="bg-[#F9F9F9]" type="number" size="h-12" name={`price`} placeholder="Price" />
                                                <textarea style={{ boxShadow: '0 4px 10px 0 rgb(0,0,0,0.04)' }} onChange={formik.handleChange} value={formik.values.description}
                                                    className={`${(formik.errors.description != null && formik.touched) ? "border-red" : "border-[#DDDDDD]"} bg-[#F9F9F9] resize-y border w-[100%] rounded-[10px] outline-none px-5 py-3`}
                                                    name={`description`} placeholder="Description" />

                                                <input className="rounded-primary transition-colors duration-100 ease-in-out cursor-pointer bg-white border border-primary hover:bg-primary hover:text-white text-primary h-11" type="button" value="Save" onClick={async () => onMilestoneSave()} />
                                            </div>}
                                    </div>

                                    <Button child='Send Estimate' extraCss={'h-11'} onClick={formik.handleSubmit} />
                                </div>
                            </form>
                        </FormikProvider>

                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default CreateEstimate;