import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as CheckMark } from "../../assets/icons/homescreen/checkmark.svg";
import { domain } from "../../axios/adapter";

const ProfileAvatar = ({ isItMe, photo, width, checkmark = true, type, profile, clickable = true }) => {
    const userSlice = useSelector((state) => state.userSlice);

    const user = userSlice.user;

    let isProvider = user.userType === 'provider';

    let url;

    if (isItMe) {
        url = isProvider ? "/ProviderPrivateProfile" : "/ClientPrivateProfile";
    }
    else {
        url = type == "provider" ? "/ProviderPublicProfile" : "/ClientPublicProfile";
    }

    let state = {
        profile: profile,
    };

    return <>
        {clickable ? <Link to={url} state={state}>
            <div className={`relative rounded-[50%] ${width} bg-lightGray cursor-pointer`}>
                {checkmark && <div className='absolute left-0 bottom-0 flex items-center justify-center rounded-[50%] bg-blue h-[40%] w-[40%]'>
                    <CheckMark className='h-[60%] w-[60%] object-cover' />
                </div>}
                <img className="overflow-clip h-full w-full rounded-[50%] aspect-square object-cover" loading='lazy' src={`${domain}/static/${photo}`}
                    alt="Profile" />
            </div>
        </Link>
            : <div className={`relative rounded-[50%] ${width} bg-lightGray cursor-pointer`}>
                {checkmark && <div className='absolute left-0 bottom-0 flex items-center justify-center rounded-[50%] bg-blue h-[40%] w-[40%]'>
                    <CheckMark className='h-[60%] w-[60%] object-cover' />
                </div>}
                <img className="overflow-clip rounded-[50%] h-full w-full aspect-square object-cover" loading='lazy' src={`${domain}/static/${photo}`}
                    alt="Profile" />
            </div>}
    </>;
};

export default ProfileAvatar;