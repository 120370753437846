import React from "react";

import { ReactComponent as Checkmark } from "../../../assets/icons/homescreen/checkmark.svg";
import { domain } from "../../../axios/adapter";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const SubscriptionCard = ({ subscription, onBuy, onUpgrade, onCancel }) => {
    return <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.03)" }}
        className="w-[600px] p-3 rounded-secondary border-[#DADADA] border flex flex-col">
        <div className="flex flex-row gap-6 items-center">
            <img className="h-[120px] w-[150px] mb-3 p-3" height="100%" width="100%" src={`${domain}/static/${subscription.icon}`} />
            <div className="flex flex-col">
                <h1 className="text-black font-semibold text-xl">{`${subscription.name} Plan`}</h1>
                <h1 className="text-[#1F1F1F] font-normal text-sm">{subscription.description}</h1>
            </div>
        </div>

        <div className="flex-grow flex flex-col border-[#EBEBEB] px-4 p-2 gap-1 border bg-[#FAFAFA] rounded-primary">
            {subscription.points.map((item, index) => <div key={`${item}-${index}`}
                className="flex flex-row items-center gap-2">
                <div className="bg-primary p-[4px] aspect-square rounded-[50%] h-[18px]">
                    <Checkmark height="100%" width="100%" />
                </div>
                <h1 className="text-black font-medium text-md">{item}</h1>
            </div>)
            }
            <h1 className="font-semibold self-center my-4 text-2xl">{`$ ${subscription.price}`}</h1>
            {onBuy != null && <OutlinedButton extracss="primary-gradient rounded-primary border-none text-white h-11 w-[50%] self-center" onClicked={() => onBuy()} label="Subscribe" />}
            {onUpgrade != null && <OutlinedButton extracss="primary-gradient rounded-primary border-none text-white h-11 w-[50%] self-center" onClicked={() => onUpgrade()} label="Upgrade" />}
            {onCancel != null && <OutlinedButton extracss="rounded-primary h-11 w-[50%] self-center" onClicked={() => onCancel()} label="Cancel plan" />}
        </div>
    </div>;
};

export default SubscriptionCard;