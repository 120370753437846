import React from "react";
import { useDispatch } from "react-redux";

import * as AlertDialog from "../../redux/slices/alertdialog";
import OutlinedButton from "../../userside/homepage/components/outlinedbutton";

const TermAndServiceDialog = ({ type, onAgree }) => {
    const dispatcher = useDispatch();

    return <div className="flex flex-col justify-between items-start py-7 px-10 w-[500px] gap-4">
        <h1 className="text-2xl">{"Identity Verification Required"}</h1>
        <h1>{`We require you to verify your identity. ${type == "provider" ? "This process involves a one-time identity verification fee of $50." : ""} By proceeding, you acknowledge and accept the terms outlined in our agreement.`}</h1>

        <a className="underline" href="http://www.onceusa.com/term-of-service" target="_blank" rel="noreferrer" >Terms and conditions</a>
        <div className="flex flex-row gap-5 w-[100%] justify-end mt-5">
            <OutlinedButton onClicked={() => {
                dispatcher(AlertDialog.hide());
            }} extracss="border-primary  border transition-all px-8 duration-200 ease-in-out hover:bg-primary hover:text-white text-primary" label={"Cancel"} />
            <OutlinedButton onClicked={() => {
                dispatcher(AlertDialog.hide());

                onAgree();
            }} extracss="primary-gradient h-11 border-none px-8 text-white" label={"Proceed & Agree"} />
        </div>
    </div>;
};

export default TermAndServiceDialog;