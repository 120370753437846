import React from "react";

import ProfileButton from "./profile_button";
import { ReactComponent as Chat } from "../../../assets/icons/dashboard/chat.svg";
import HalfStar from "../../../assets/icons/homescreen/half-star.svg";
import Star from "../../../assets/icons/homescreen/star-fill.svg";

export default function RatingAndMessages({ onReview, onMessage, reviews, reviewsCount, messageLabel }) {
    return <div className="flex flex-row w-[300px] gap-7">
        <ProfileButton onClick={onReview} extraCss="flex justify-center flex-grow flex-1 h-[80px]" child={
            <div className="flex flex-col items-center justify-center self-center gap-1">
                <div className="flex gap-1">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <img key={index} className={`h-4 w-4 aspect-square object-contain ${!(((index + 1) - reviews) < 1 &&
                            ((index + 1) - reviews) > 0) && !((index + 1) <= reviews) ? "filter-grey" : ""}`}
                            src={((index + 1) - reviews) < 1 &&
                                ((index + 1) - reviews) > 0 ? HalfStar : Star}
                        />
                    ))}
                </div>
                <h1 className="font-semibold text-opacity-50 text-darkGray text-sm">{`${reviewsCount} Reviews`}</h1>
            </div>
        } />
        <ProfileButton onClick={onMessage} extraCss="flex justify-center flex-1 h-[80px]" child={
            <div className="flex-col items-center justify-center self-center flex gap-2">
                <Chat />
                <h1 className="font-semibold text-opacity-50 text-darkGray text-sm">{messageLabel}</h1>
            </div>
        } />
    </div>;
}