import { deleteToken } from "firebase/messaging";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../../assets/icons/dashboard/edit-icon.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { ReactComponent as LogOut } from "../../../assets/icons/dashboard/logout.svg";
import { domain } from "../../../axios/adapter";
import * as Cache from "../../../cache/cachemanager";
import { messaging } from "../../../integrations/firebaseconfig";
import JobListingComponent from "../../jobs/components/joblistingscomponent";
import RatingAndMessages from "../components/ratingAndMessages";

const ClientPrivateProfile = () => {
    const userSlice = useSelector((state) => state.userSlice);
    const profile = userSlice.profile;

    const navigate = useNavigate();

    const onLogOut = async () => {
        await Cache.removeAll();
        await deleteToken(messaging);

        navigate("/");
        window.location.reload();
    };

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <h1 className="text-black font-semibold mb-10 text-3xl mt-12">{<span className="font-light">My</span>} Profile</h1>
                        <div className="flex flex-row justify-between w-[100%] flex-wrap gap-5">
                            <div className="flex flex-row gap-4 justify-start">
                                <div className="rounded-[50%] h-[100px] w-[100px] flex-shrink-0">
                                    <img style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.1)" }}
                                        className="border-white object-cover aspect-square rounded-[50%] h-full w-full p-[3px]"
                                        src={`${domain}/static/${profile.profileImage}`} alt="profile" />
                                </div>
                                <div className="flex flex-col gap-2 my-3 w-[60%]">
                                    <h1 className="font-semibold text-lg">{`${profile.firstName} ${profile.lastName}`}</h1>
                                    <div className="flex flex-row justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2">{`${profile.location}, ${profile.landmark}, ${profile.state}`}</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-5 items-end">
                                <RatingAndMessages messageLabel="Chats" onMessage={() => navigate("/Chatrooms")}
                                    onReview={() => navigate("/ReviewListScreen", { state: { fetchUrl: `reviews/profile/${profile.uuid}/all` } })}
                                    reviews={profile.reviews} reviewsCount={profile.reviewsCount} />

                                <div className="flex flex-row gap-7 h-min">
                                    <div onClick={() => onLogOut()}
                                        className="cursor-pointer py-2 px-3 capitalize rounded-[10px] bg-primary justify-center transition-all duration-200 ease-in-out flex flex-row items-center gap-2">
                                        <LogOut />
                                        <h1 className="flex flex-row gap-2 text-white font-semibold text-md">Log Out</h1>
                                    </div>
                                    <Link to="/profileEdit" style={{ backgroundColor: "#F2F2F2", border: "1px solid #DDDDDD" }}
                                        className="filter-icon py-2 capitalize rounded-[10px] w-[100px] justify-center transition-all duration-200 ease-in-out flex flex-row items-center gap-2">
                                        <EditIcon />
                                        <h1 className="flex flex-row gap-2 text-darkGray font-semibold text-md">Edit</h1>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <JobListingComponent />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default ClientPrivateProfile;