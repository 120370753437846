import React from "react";

import { ReactComponent as Pending } from "../../../assets/icons/dashboard/pending.svg";
import { ReactComponent as Checkmark } from "../../../assets/icons/homescreen/checkmark.svg";

const EstimateContainer = ({ item, paymentStatus }) => {
    return <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border py-4 items-start mb-8 rounded-primary flex flex-col w-[100%] border-card-border">
        <div className="flex flex-col items-start justify-between w-[100%] gap-4">
            <div className="flex px-5 flex-row w-[100%] justify-between h-8">
                <h1 className="text-black font-medium text-xl line-clamp-2">{item.milestones.length === 1 ? "One Time Payment" : "Milestone Payment"}</h1>
                {paymentStatus != null &&
                    <div className="flex flex-row gap-2 items-center">
                        <div className="h-5 w-5">
                            {paymentStatus === "pending" ?
                                <Pending />
                                : <div className="w-[100%] h-[100%] rounded-[50%] bg-primary flex justify-center items-center">
                                    <Checkmark className="h-[60%] w-[60%]" />
                                </div>
                            }
                        </div>
                        <h1 className={`${paymentStatus === "pending" ? "text-[#DBC500]" : "text-primary"} font-semibold text-md`}>{paymentStatus}</h1>
                    </div>}
            </div>

            <div className="flex flex-col self-stretch pe-5 ps-5 overflow-hidden">
                {item.milestones.map((milestone) => (
                    <div key={milestone.uuid} className={`estimate-containter ${milestone.acceptedStatus == 1 ? "completed" : ""} flex flex-row items-stretch pl-6 pb-5`}>
                        <div className="flex flex-col flex-grow justify-stretch">
                            <div className="flex flex-row justify-between mt-[-5px]">
                                <h1 className="text-black font-semibold text-lg line-clamp-1">{milestone.name}</h1>
                                <h1 className="text-black font-semibold text-lg line-clamp-1">{`$${milestone.price}`}</h1>
                            </div>
                            <h1 className="text-darkGray font-normal text-sm my-1 mb-3">{milestone.description}</h1>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div >;
};

export default EstimateContainer;