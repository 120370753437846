import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
// import { fromAddress } from "react-geocode";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as ProfileEditIcon } from "../../../assets/icons/homescreen/profile_edit.svg";
import { ReactComponent as ProfilePlaceHolder } from "../../../assets/icons/homescreen/profile_placeholder.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as CacheManager from "../../../cache/cachemanager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import Button from "../../../global/components/buttton";
import TextField from "../../../global/components/textfield";
import { toFormData } from "../../../global/methods/toformdata";
import * as RegExp from "../../../global/variables/regexp";
import { updateProfile } from "../../../redux/slices/user";

const ProfileEdit = () => {
  const userSlice = useSelector((state) => state.userSlice);
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { firstTime } = location?.state ?? { firstTime: false };

  const profile = userSlice.profile;
  const profileCompletion = userSlice.profileCompletion;
  const user = userSlice.user;

  const disabled = (user.userType == "provider" &&
    ((profileCompletion?.checkrVerification ?? 0) == 1)) ||
    (user?.userType != "provider" &&
      ((profileCompletion?.userVerification ?? 0) == 1));

  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);

  const initialData = {
    ...profile,
  };

  const formSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Required").matches(RegExp.phoneNumberRegExp, "Invalid phonenumber"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    landmark: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    dob: Yup.date()
      .required('Required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'You must be at least 18 years old'),
    bio: Yup.string()
      .required('Required')
      .max(256, "Max characters limit - 256")
  });

  const onSubmit = async (values) => {
    if (firstTime == true && file == null) {
      return showToast("No Profile image selected");
    }

    // const { results } = await fromAddress(`${values.location}, ${values.landmark} - ${values.pincode}`);

    // const { lat, lng } = results[0].geometry.location;

    // body.latitude = lat;
    // body.longitude = lng;

    Loader.show();

    const body = {
      ...values,
      profileImage: blob,
    };

    delete body.id;
    delete body.uuid;
    delete body.createdAt;
    delete body.updatedAt;
    delete body.userId;
    delete body.reviews;
    delete body.reviewsCount;

    if (blob == null) {
      delete body.profileImage;
    }

    const [response, error] = await fetch({
      requestType: firstTime != true ? "put" : "post",
      route:
        firstTime != true
          ? `profiles/user/${profile.uuid}`
          : `profiles/${user.uuid}`,
      body: toFormData(body),
    });

    if (error != null) {
      showToast(error);
      return Loader.hide();
    }

    Loader.hide();

    if (firstTime != true) {
      delete body.profileImage;

      dispatcher(
        updateProfile({
          ...values,
          profileImage: response.res.profileImage ?? profile.profileImage,
        })
      );

      response.res = {
        ...values,
        profileImage: response.res.profileImage ?? profile.profileImage,
      };

      await CacheManager.set("profileData", {
        ...values,
        profileImage: response.res.profileImage ?? profile.profileImage,
      });

      navigate(-1);
      return;
    }

    await CacheManager.set("profileData", response.res);

    dispatcher(updateProfile(response.res));

    if (firstTime != true) {
      navigate(-1);
    }
    else {
      if (user.userType == "provider") {
        navigate("/ProviderVerificationScreen");
      }
      else {
        navigate("/ClientVerificationScreen");
      }
    }
  };
  const formik = useFormik({
    initialValues: initialData,
    onSubmit: onSubmit,
    validationSchema: formSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const onProfileClick = () => {
    const input = document.getElementById("file_input");
    input.click();
  };

  const oninputchange = (e) => {
    const file = e.target.files[0];
    if (file == null) {
      return;
    }
    setBlob(file);
    const fileUrl = URL.createObjectURL(file);
    setFile(fileUrl);
  };

  return (
    <main>
      <section>
        <div className="flex flex-col w-[100%]">
          <div className="flex flex-col mx-10 items-center justify-start">
            <div className="mt-6 w-[100%] xl:px-32 lg:px-20">
              <h1 className="text-black font-normal mb-10 text-3xl mt-12">
                Profile<span className="font-semibold"> Edit</span>
              </h1>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className="bg-white flex flex-col gap-8 rounded-primary 2xl:w-[1200px] border-card-border border p-10"
                    style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.05)" }}
                  >
                    <div
                      onClick={() => onProfileClick()}
                      style={{ boxShadow: "0px 0px 12px 6px rgba(0,0,0,0.04)" }}
                      className="h-32 w-32 mx-auto rounded-[50%] p-1 bg-white cursor-pointer"
                    >
                      <div className="rounded-[50%] bg-Gray78 h-[100%] w-[100%] grid place-content-center relative">
                        {file == null && profile.profileImage == "" && (
                          <ProfilePlaceHolder />
                        )}
                        {file != null && (
                          <img
                            src={file}
                            className="rounded-[50%] object-cover aspect-square h-full w-full"
                          />
                        )}
                        {file == null && profile.profileImage != "" && (
                          <img
                            src={`${domain}/static/${profile.profileImage}`}
                            className="rounded-[50%] object-cover aspect-square h-full w-full"
                          />
                        )}

                        <div className="bg-white p-2 absolute right-1 bottom-1 rounded-[50%] grid place-content-center">
                          <ProfileEditIcon />
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none", position: "absolute" }}
                          id="file_input"
                          onChange={(e) => oninputchange(e)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
                      <TextField
                        error={formik.errors.firstName != null && formik.touched}
                        disabled={disabled}
                        size="h-12"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.lastName != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.phoneNumber != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone number"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.dob != null && formik.touched}
                        size="h-12"
                        type="date"
                        name="dob"
                        placeholder="Date of Birth"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.location != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="location"
                        placeholder="Address"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.landmark != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="landmark"
                        placeholder="City"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.state != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="state"
                        placeholder="State"
                      />
                      <TextField
                        disabled={disabled}
                        error={formik.errors.pincode != null && formik.touched}
                        size="h-12"
                        type="text"
                        name="pincode"
                        placeholder="Zipcode"
                      />
                      <div className='flex flex-col flex-grow'>
                        <textarea onChange={formik.handleChange} value={formik.values.bio}
                          className={`${(formik.errors.bio != null && formik.touched) ? "border-red" : "border-textfield-stroke"} bg-textfield-back min-h-[100px] resize-none flex-grow border w-[100%] rounded-[10px] outline-none px-5 py-3`}
                          name="bio" placeholder="About me" />
                        <p className='text-xs text-red' >{formik.errors.bio}</p>
                      </div>
                    </div>
                    <Button child="Save" extraCss="self-center h-11 w-[25%]" />
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProfileEdit;
