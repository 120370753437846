import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as HalfStar } from "../../../assets/icons/homescreen/half-star.svg";
import { ReactComponent as StarFilled } from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";


const ExpertItem = ({ reviews, profile, title, state, path }) => {
    return <Link state={state} to={path} style={{ backgroundColor: "#F9F9F9", border: "1px solid #DDDDDD" }} className="w-[100%] rounded-primary p-5 py-3 flex flex-row gap-4 justify-between items-center">
        <img className="rounded-[50%] bg-center aspect-square object-cover w-12" src={`${domain}/static/${profile.profileImage}`} alt="profile" />
        <div className="flex flex-col flex-grow justify-around">
            <h1 className="font-semibold line-clamp-1">{title}</h1>
            <div className="flex flex-row justify-start items-center flex-nowrap">
                {Array.from({ length: 5 }).map((_, index) => {
                    return (((index + 1) - reviews) < 1 &&
                        ((index + 1) - reviews) > 0 ?
                        <HalfStar className="h-4 w-4" height="100%" width="100%" key={`highlighted-${index}`} /> :
                        <StarFilled className="h-4 w-4" height="100%" style={(index + 1) <= reviews ? {} : { filter: "invert(76%) sepia(70%) saturate(17%) hue-rotate(358deg) brightness(184%) contrast(84%)" }}
                            key={`star-${index}`} />);
                })}
                <h1 className="ml-1 text-sm font-medium line-clamp-1">/ 5</h1>
            </div>
        </div>
        <Link state={{ profile: profile }} to="/ProviderPublicProfile">
            <div style={{ backgroundColor: "#F0F0F0", border: '1px solid #8D8D8D', color: '#8D8D8D' }} className="text-sm text-center cursor-pointer border rounded-[8px] line-clamp-1 p-2 h-[10%]">
                View Profile
            </div>
        </Link>
    </Link>;
};

export default ExpertItem;