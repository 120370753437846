import React from "react";
import { Link, useLocation } from "react-router-dom";

import ForwardIcon from "../../../assets/icons/dashboard/arrow-forward.svg";

export default function ListingsScreen() {
    const location = useLocation();

    const { mainListing, listings } = location.state;

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <h1 className="text-black font-semibold col-span-full mx-4 mb-3 self-start text-3xl mt-12">{mainListing.name}</h1>
                <div className="flex flex-col px-10 gap-2 items-center justify-center w-[100%]">
                    {listings.map(listing => <Link to="/ExpertsScreen" key={listing.uuid} state={{
                        listing: listing,
                    }} className="w-full">
                        <div className="bg-white cursor-pointer border border-textfield-stroke w-[100%] h-11 rounded-primary p-5 py-3 flex flex-row gap-4 justify-between items-center" key={listing.uuid}>
                            <h4 className="text-black font-normal text-md capitalize">{listing.type}</h4>
                            <img className="h-3 w-3 aspect-square object-contain" src={ForwardIcon} />
                        </div>
                    </Link>)}
                </div>
            </div>
        </section>
    </main>;
}