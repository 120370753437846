import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Star from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";
import * as LoadingBar from "../../../redux/slices/toploadingbar";

export default function ReviewListScreen() {
    const location = useLocation();
    const dispatcher = useDispatch();

    const { fetchUrl } = location.state;

    useEffect(() => {
        fetchReviews();
    });

    async function fetchReviews(offset, limit) {
        dispatcher(LoadingBar.setProgress(50));
        Loader.show();

        const [reviewsData, reviewsError] = await fetch({
            route: fetchUrl,
            requestType: "get",
            params: {
                limit: limit,
                offset: offset,
                sort: "DESC",
            }
        });

        if (reviewsError != null) {
            dispatcher(LoadingBar.setProgress(100));
            Loader.hide();

            return showToast(reviewsError);
        }

        Loader.hide();
        dispatcher(LoadingBar.setProgress(100));

        return reviewsData.res;
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-center">
                    <div className="flex flex-row justify-between w-[100%] h-14 items-center text-3xl mt-12 mb-3">
                        <h1 className="text-black font-semibold col-span-full ">Reviews</h1>
                    </div>
                    <div className="w-full">
                        <PaginatedScrollView pageRequest={fetchReviews} className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 place-items-start gap-5 my-6" limit={10}
                            onNotFound="No reviews found yet. Stay tuned!" child={(item) => {
                                return <div key={item.uuid} style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.02)" }} className="bg-white border p-4 items-start mb-8 rounded-primary flex flex-col w-[100%] border-card-border">
                                    <div className="flex flex-row mb-1 gap-3">
                                        <img className="h-[50px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${item.profile.profileImage}`} alt="profile" />
                                        <div className="flex flex-col flex-grow">
                                            <h1 className="text-black font-semibold text-md line-clamp-2">{`${item.profile.firstName} ${item.profile.lastName}`}</h1>
                                            <div className="flex gap-1">
                                                {Array.from({ length: 5 }).map((_, index) => (
                                                    <img key={index} className={`h-4 w-4 aspect-square object-contain ${index + 1 > item.reviews ? "filter-grey" : ""}`}
                                                        src={Star}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-sm font-medium">{item.review}</p>
                                    <div className="flex flex-row flex-wrap gap-2 mt-4">
                                        {item.images.map((image, index) =>
                                            <img key={index} className="h-[50px] w-[50px] rounded-[5px] object-cover" src={`${domain}/static/${image}`} alt={`pic-${index}`} />
                                        )}
                                    </div>
                                </div>;
                            }} />
                    </div>
                </div>
            </div>
        </section>
    </main>;
}