import React from "react";

export default function highlightString({ strTarget, subStr, styles }) {
    if (subStr.length == 0) {
        return <span className={styles}>{strTarget}</span>;
    }

    var reg = new RegExp(subStr, 'gi');

    var marked_str = strTarget.replace(reg, function (str) { return '|[' + str + ']|'; });

    var arr = marked_str.split('|');

    var result = arr.map(function (s) {
        if (s.startsWith('[') && s.endsWith(']')) {
            s = s.replace(/\||\[|\]/g, '');
            return (<span key={s} className={`bg-Gray46 text-white ${styles}`}>{s}</span>);
        }
        else {
            return <span key={s} className={styles}>{s}</span>;
        }
    });
    if (result)
        return <span className={styles}>{result}</span>;
    else
        return <span className={styles}></span>;
}