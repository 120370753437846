import React, { useEffect, useState } from "react";

import fetch from "../../axios/manager";
import * as Loader from "../../dialogs/loader/loaderop";
import showToast from "../../dialogs/loader/toast";
import myDateFormat from "../methods/date-format";

export default function TermsAndConditions() {
    const [page, setPage] = useState(null);

    useEffect(() => {
        fetchPage();
    }, []);

    async function fetchPage() {
        Loader.show();

        const [pageData, pageError] = await fetch({
            route: "admins/pages/path/terms-and-conditions",
            requestType: "get"
        });

        if (pageError != null) {
            showToast(pageError);
            return Loader.hide();
        }

        Loader.hide();

        setPage({ ...pageData.res });
    }

    return <div className="page">
        {page != null && <div className="flex flex-col">
            <div className="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-16 pb-6 mx-auto">
                <h1 className="mb-2 font-bold text-4xl">{page.title}</h1>
                <p className="text-gray-700 dark:text-neutral-400">Last updated: {myDateFormat(page.updatedAt)}</p>
            </div>

            <div className="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
                <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </div>
        </div>}
    </div>;
}