import { ErrorMessage, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import Turnstile, { useTurnstile } from "react-turnstile";
import * as Yup from "yup";

import OtpDialog from "./otp_dialog";
import fetch from "../../axios/manager";
import Button from "../../global/components/buttton";
import TextField from "../../global/components/textfield";
import * as Env from "../../global/variables/env";
import { show } from "../../redux/slices/alertdialog";
import { setProgress } from "../../redux/slices/toploadingbar";
import * as Loader from "../loader/loaderop";
import showToast from "../loader/toast";

const AlertDialogLoginBody = () => {
    const initialValues = {
        email: "",
        token: "",
    };

    const dispatcher = useDispatch();
    const turnstile = useTurnstile();

    const onSubmit = async (values) => {
        console.log(values);

        Loader.show();

        const [, otpError] = await fetch({
            route: "/users/sendOtp",
            requestType: "post",
            body: {
                email: values.email,
                // token: values.token,
            },
        });

        if (otpError != null) {
            turnstile.reset();

            return onError(otpError);
        }

        Loader.hide();

        dispatcher(
            show({
                child: (
                    <OtpDialog email={values.email} />
                ),
            })
        );
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    const formSchema = Yup.object().shape({
        email: Yup.string().required("Required").email("Invalid email"),
        // token: Yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validateOnBlur: true,
        validationSchema: formSchema,
    });

    return (
        <div className="flex flex-col justify-between items-stretch py-10 px-14 w-[500px]">
            <h2 className="text-center md:text-4xl text-3xl uppercase font-light leading-normal text-darkGray mb-9">
                Log <span className="font-bold">in</span>
            </h2>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-5 items-stretch w-[100%]">
                        <TextField
                            error={
                                formik.errors.email != null && formik.touched.phoneNumber
                            }
                            size="h-12"
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                        />

                        <div className="flex flex-col w-full">
                            <Turnstile
                                appearance="execute"
                                size="flexible"
                                sitekey={Env.TURNSTILE_SECRET}
                                onVerify={(token) => {
                                    formik.setFieldValue("token", token);
                                }}
                            />
                            <ErrorMessage className="text-red text-xs" component="p" name="token" />
                        </div>

                        <Button child="Log In" extraCss="mb-5 h-11" />
                    </div>
                </form>
            </FormikProvider>
        </div>
    );
};

export default AlertDialogLoginBody;
