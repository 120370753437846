import dateFormat from 'dateformat';
import React from "react";

import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { domain } from "../../../axios/adapter";

const JobItem = ({ item }) => {
    return <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.03)" }} className="bg-white border hover:border-primary transition-all duration-200 ease-in-out cursor-pointer gap-4 p-3 items-center rounded-primary h-40 flex flex-row w-[100%] border-card-border">
        <img className="h-32 w-32 rounded-secondary object-cover aspect-square" src={`${domain}/static/${item.images[0]}`} alt="card" />
        <div className="flex-grow flex flex-col gap-2 items-start justify-around">
            <h1 className="text-black font-semibold text-xl line-clamp-2">{item.title}</h1>
            <div className="flex flex-row justify-start items-start gap-2">
                <Location className="h-5 w-5 aspect-square object-contain mt-[1px]" height="100%" width="100%" />
                <h1 className="text-lightGray text-xsm mr-5 line-clamp-2">{`${item.address}, ${item.city}, ${item.state}`}</h1>
            </div>
            <div className="flex flex-row justify-start items-start gap-2">
                <Calender className="h-4 w-5 aspect-square object-contain mt-[1px]" height="100%" width="100%" />
                <h1 className="text-lightGray text-xs mr-5 line-clamp-1">{dateFormat(item.jobTime, "d mmm, yyyy | hh:mmtt")}</h1>
            </div>
        </div>
    </div>;
};

export default JobItem;