import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "user": {
        "id": -1,
        "phoneNumber": "",
        "userType": "",
        "role": "",
        "uuid": "",
        "token": "",
        "createdAt": "",
        "updatedAt": ""
    },
    "profile": {
        "id": -1,
        "userId": -1,
        "reviews": -1,
        "reviewsCount": -1,
        "firstName": "",
        "lastName": "",
        "email": "",
        "profileImage": "",
        "location": "",
        "landmark": "",
        "state": "",
        "pincode": "",
        "uuid": "",
        "createdAt": "",
        "updatedAt": ""
    },
    "profileCompletion": {
        "id": -1,
        "userId": -1,
        "userVerification": -1,
        "subscriptionId": -1,
        "checkrStatus": 0,
        "checkrVerification": 0,
        "uuid": "",
        "createdAt": "",
        "updatedAt": "",
        "subscription": {
            "points": [],
            "id": -1,
            "name": "",
            "icon": "",
            "description": "",
            "price": -1,
            "type": "",
            "uuid": "",
            "createdAt": "",
            "updatedAt": ""
        }
    }
};

const userSlice = createSlice({
    name: 'userData',
    initialState: initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = {
                ...state.user,
                ...action.payload,
            };
        },
        updateProfile: (state, action) => {
            state.profile = {
                ...state.profile,
                ...action.payload,
            };
        },
        updateCompletion: (state, action) => {
            state.profileCompletion = {
                ...state.profileCompletion,
                ...action.payload,
            };
        },
        logOut: (state) => {
            state.user = null;
            state.profile = null;
            state.profileCompletion = null;
        }
    }
});

export const { updateCompletion, updateProfile, logOut, updateUser } = userSlice.actions;
export default userSlice.reducer;