import { createSlice } from "@reduxjs/toolkit";

const notificationsData = {
    notificationData: {},
    notificationCount: 0,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: notificationsData,
    reducers: {
        setNotifications: (state, actions) => {
            const { chatroomUUID, channelUUID, count } = actions.payload;

            state.notificationData = {
                ...state.notificationData,
                [`${chatroomUUID}~${channelUUID}`]: count,
            };
        },
        setNotificationCount: (state, actions) => {
            const { count } = actions.payload;

            state.notificationCount = count;
        },
        decrementNotificationCount: (state) => {
            state.notificationCount = state.notificationCount - 1;
        },
        incrementNotificationCount: (state) => {
            state.notificationCount = state.notificationCount + 1;
        },
    }
});

export const { setNotificationCount, setNotifications, decrementNotificationCount, incrementNotificationCount } = notificationsSlice.actions;
export default notificationsSlice.reducer;