import React from "react";

import CompletedGigItem from "./completedGigItem";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";

const StaggeredListView = ({ items, columns, horizontalGap, verticalGap, className, pageRequest, mchildren, onNotFound }) => {
    const divide = () => {
        if (columns === 1) {
            return [items];
        }
        let myItems = [];
        for (let i = 0; i < items.length; i += columns) {
            myItems.push(items.slice(i, i + columns));
        }

        return rotateMatrix(myItems);
    };

    function rotateMatrix(matrix) {
        if (matrix.length === 0) return [];

        const transposedMatrix = matrix[0].map((_, colIndex) =>
            matrix.map(row => row[colIndex])
        );
        return transposedMatrix.map(row => row);
    }

    const children = divide();

    return <PaginatedScrollView onNotFound={onNotFound} className={`w-[100%] flex flex-row ${horizontalGap} ${className}`} pageRequest={pageRequest}
        innerWidgets={mchildren ?? (children.map((column, index) => (
            <div key={index} className={`flex flex-col ${children.length == 1 ? `w-[${100 / columns}%]` : 'flex-grow'} ${verticalGap}`}>
                {column.map((item) =>
                    <CompletedGigItem key={item.uuid} item={item} />
                )}
            </div>)))
        } />;
};

export default StaggeredListView;