import React from "react";
import { useDispatch } from "react-redux";

import * as AlertDialog from "../../redux/slices/alertdialog";
import OutlinedButton from "../../userside/homepage/components/outlinedbutton";

const BasicDialog = ({ title, description, onAgree, positiveButtonText, negativeButtonText }) => {
    const dispatcher = useDispatch();

    return <div className="flex flex-col justify-between items-start py-7 px-10 w-[500px] gap-4">
        <h1 className="text-2xl">{title}</h1>
        {description}

        <div className="flex flex-row gap-5 w-[100%] justify-end mt-5">
            <OutlinedButton onClicked={() => {
                dispatcher(AlertDialog.hide());
            }} extracss="border-primary  border transition-all px-8 duration-200 ease-in-out hover:bg-primary hover:text-white text-primary" label={negativeButtonText} />
            <OutlinedButton onClicked={() => {
                dispatcher(AlertDialog.hide());

                onAgree();
            }} extracss="primary-gradient h-11 border-none px-8 text-white" label={positiveButtonText} />
        </div>
    </div>;
};

export default BasicDialog;