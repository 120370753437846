import { createSlice } from "@reduxjs/toolkit";

const chatroomsData = {};

const chatroomsSlice = createSlice({
    name: 'chatrooms',
    initialState: chatroomsData,
    reducers: {
        setChatroom: (state, actions) => {
            const { item } = actions.payload;
            state[item.uuid] = item;
        },
        updateChatroom: (state, actions) => {
            const { item } = actions.payload;
            state[item.uuid] = {
                ...state[item.uuid],
                ...item,
            };
        },
        addChat: (state, actions) => {
            const { chatroomUUID, chat, channelUUID, last } = actions.payload;

            if (state[chatroomUUID] == null) return;

            state[chatroomUUID].chats ??= {};
            state[chatroomUUID].chats[channelUUID] ??= {};
            if (last) {
                state[chatroomUUID].chats[channelUUID] = {
                    [chat.uuid]: chat,
                    ...state[chatroomUUID].chats[channelUUID],
                };
            }
            else {
                state[chatroomUUID].chats[channelUUID] = {
                    ...state[chatroomUUID].chats[channelUUID],
                    [chat.uuid]: chat,
                };
            }

            if (last == true) {
                state[chatroomUUID].channels[channelUUID] = {
                    ...state[chatroomUUID].channels[channelUUID],
                    chat: chat,
                };
            }
        },
        updateChat: (state, actions) => {
            const { chat, chatroomUUID, channelUUID } = actions.payload;

            state[chatroomUUID].chats[channelUUID][chat.uuid] = chat;
        },
        setChats: (state, actions) => {
            const { chats, chatroomUUID, channelUUID } = actions.payload;

            for (let i = 0; i < chats.length; i++) {
                state[chatroomUUID].chats[channelUUID][chats[i].uuid] = chats[i];
            }
        },
        markReadAll: (state, actions) => {
            const { chatroomUUID, channelUUID, readStatus, mine, profileId } = actions.payload;

            const chatroom = state[chatroomUUID];
            const chats = chatroom.chats[channelUUID];

            if (chatroom == null || chats == null) return;

            for (let i = 0; i < chats.length; i++) {
                const chat = chats[i];
                const isSentFromMe = chat.sentFrom == profileId;

                if (isSentFromMe && !mine) {
                    chat.readStatus = readStatus;
                }
                else if (isSentFromMe && mine) {
                    chat.readStatus = readStatus;
                }
            }
        }
    }
});

export const { setChats, addChat, setChatroom, updateChatroom, updateChat, markReadAll } = chatroomsSlice.actions;
export default chatroomsSlice.reducer;