import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Cache from "../../../cache/cachemanager";
import BasicDialog from "../../../dialogs/alertdialog/basic_dialog";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import usePageVisibility from "../../../global/hooks/page_visibility";
import goTo from "../../../global/methods/goto";
import * as Alertdialog from "../../../redux/slices/alertdialog";
import { updateCompletion } from "../../../redux/slices/user";
import SubscriptionCard from "../widgets/subscriptionCard";

function SubscriptionPickScreen() {
    const userSlice = useSelector((state) => state.userSlice);
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const currentSubscription = userSlice.profileCompletion.subscription;

    const state = location.state;

    const upgrade = state?.upgrade ?? false;

    const user = userSlice.user;
    const type = user.userType;

    const [subscriptions, setSubscriptions] = useState([]);

    const [activeBoughtSubcription, setActiveBoughtSubcription] = useState(null);

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    function onSubscriptionClicked(subscription) {
        setActiveBoughtSubcription(subscription);

        if (upgrade) {
            onUpgradeClicked(subscription);
        }
        else {
            goTo(`https://payments.onceusa.com/${user.uuid}/subscription/${subscription.uuid}`);
        }
    }

    function onUpgradeClicked(subscription) {
        dispatcher(Alertdialog.show({
            child: <BasicDialog title="Confirm Subscription Change"
                description={<div className="flex flex-col">
                    <h1>{`You are about to upgrade your subscription to ${subscription.name}. Please review the following details:`}</h1>
                    <h1>Please review the following details.</h1>
                    <ul>
                        <li>{`Your current plan (${currentSubscription.name}) will be cancelled immediately.`}</li>
                        <li>{`Your new plan (${subscription.name}) will start right away.`}</li>
                        <li>{`You will be billed immediately for the new plan at $${subscription.price}/month.`}</li>
                        <li>Any unused time on your current plan will not be refunded or prorated.</li>
                    </ul>
                    <h1 className="font-semibold">Are you sure you want to proceed with this change?</h1>
                </div>} positiveButtonText="YES, UPGRADE" negativeButtonText="BACK" onAgree={() => upgradeSubscription(subscription)} />
        }));
    }

    function upgradeSubscription(subscription) {
        goTo(`https://payments.onceusa.com/${user.uuid}/subscription/${subscription.uuid}?upgrade=1`);
    }

    usePageVisibility(onBlur, onFocus);

    function onBlur() {
        console.log("going on background");
    }

    async function onFocus() {
        Loader.show();

        const [profileCompletionData, profileCompletionError] = await fetch({
            route: `/users/${user.uuid}/profileCompletion`,
            requestType: "get",
        });

        if (profileCompletionError != null) {
            Loader.hide();

            showToast(profileCompletionError);
            return;
        }

        dispatcher(updateCompletion(profileCompletionData["res"]));

        Loader.hide();

        if (profileCompletionData["res"].subscription.uuid == activeBoughtSubcription.uuid) {
            onComplete(activeBoughtSubcription);
        }
    }

    const onComplete = async (subscription) => {
        Loader.show();

        const [, error] = await fetch({
            route: `/subscriptions/buy/${user.uuid}`,
            requestType: "put",
            body: {
                "subscriptionUUID": subscription.uuid,
            }
        });

        Loader.hide();

        if (error != null) {
            return Loader.hide();
        }

        dispatcher(updateCompletion({ subscription: subscription }));
        await Cache.set("profileCompletionData", {
            ...(userSlice.profileCompletion),
            subscription: subscription,
        });

        navigate("/dashboard");
    };

    const fetchSubscriptions = async () => {
        Loader.show();

        const [subscriptionsData, error] = await fetch({
            route: "/subscriptions/getAll",
            requestType: "get",
            params: {
                type: type,
            },
            cacheOptions: "fromCache",
            cacheKey: `${type}subscriptions`,
        });

        if (error != null) {
            Loader.hide();
            return showToast(error);
        }

        Loader.hide();

        let subscriptions;

        if (upgrade) {
            subscriptions = subscriptionsData.res.filter(subscription => subscription.uuid != currentSubscription.uuid);
        }
        else {
            subscriptions = subscriptionsData.res;
        }

        setSubscriptions(subscriptions);
    };

    return <div className="flex flex-col w-[100%] mt-10">
        <div className="flex flex-row mx-10 items-start justify-start gap-6 mb-10 max-lg:flex-col max-lg:items-center">
            {subscriptions.map((item) => {
                return <SubscriptionCard key={item.uuid} subscription={item} onBuy={() => onSubscriptionClicked(item)} />;
            })}
        </div>
    </div>;
}

export default SubscriptionPickScreen;