import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AlertDialog from "./dialogs/alertdialog/alertdialog";
import Loader from "./dialogs/loader/loader";
import LoadingBarComponent from "./dialogs/loader/toploadingbar";
import AuthHandler from "./global/components/authhandler";
import Header from './global/components/header';
import PageNotFound from "./global/screens/404";
import Feedback from "./global/screens/feedback";
import ContactUs from "./global/screens/help-center";
import PrivacyPolicy from "./global/screens/privacy-policy";
import TermsAndConditions from "./global/screens/terms-and-conditions";
import { register } from "./integrations/serviceWorker";
import store from "./redux/store";
import ChatDashboard from "./userside/chats/screens/chatdashboard";
import ChatRoomScreen from "./userside/chats/screens/chatroomscreen";
import CreateEstimate from "./userside/chats/screens/createestimate";
import ShowEstimateScreen from "./userside/chats/screens/showestimatscreen";
import CompletedGigsListScreen from "./userside/dashboard/screens/completedGigs_listScreen";
import CompletedGigScreen from "./userside/dashboard/screens/completedgigscreen";
import Dashboard from "./userside/dashboard/screens/dashboard";
import FindAProfessional from "./userside/dashboard/screens/findaprofessional";
import ListingsScreen from "./userside/dashboard/screens/listingsscreen";
import LookForWork from "./userside/dashboard/screens/lookforwork";
import MyServiceOffering from "./userside/dashboard/screens/myserviceoffering";
import Home from './userside/homepage/screens/Home';
import "./global/styles/index.css";
import AddJob from "./userside/jobs/screens/addjob";
import AddService from "./userside/jobs/screens/addservice";
import ApplicantsScreen from "./userside/jobs/screens/applicants_screen";
import ExpertScreen from "./userside/jobs/screens/experts_screen";
import JobInfo from "./userside/jobs/screens/job_info";
import MyJobListing from "./userside/jobs/screens/joblisting";
import ServiceInfo from "./userside/jobs/screens/service_info";
import WalletScreen from "./userside/payments/screens/walletscreen";
import ClientPrivateProfile from "./userside/profile/screens/client_private";
import ClientPublicProfile from "./userside/profile/screens/client_public";
import ProfileEdit from "./userside/profile/screens/profile_edit";
import ProviderPrivateProfile from "./userside/profile/screens/provider_private";
import ProviderPublicProfile from "./userside/profile/screens/provider_public";
import ReviewListScreen from "./userside/profile/screens/reviewListScreen";
import ClientVerificationScreen from "./userside/starting/screens/client_verification_screen";
import PickUserTypeScreen from "./userside/starting/screens/picktypescreen";
import ProviderVerificationScreen from "./userside/starting/screens/provider_verification_screen";
import SubscriptionInfo from "./userside/subscriptions/screens/subcription_info";
import SubscriptionPickScreen from "./userside/subscriptions/screens/subscriptions_pick_screen";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Loader />
    <LoadingBarComponent />
    <ToastContainer
      progressClassName="rounded-primary"
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      stacked
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />

    <BrowserRouter>
      <Routes>
        <Route path="/*" element={
          <div className="h-[100dvh] flex flex-col">
            <AlertDialog />
            <Header />
            <Routes>
              <Route path="/SubcriptionPickScreen" element={<SubscriptionPickScreen />} />
              <Route path="/pickUserTypeScreen" element={<PickUserTypeScreen />} />
              <Route path="/ClientVerificationScreen" element={<ClientVerificationScreen />} />
              <Route path="/ProviderVerificationScreen" element={<ProviderVerificationScreen />} />
              <Route element={<AuthHandler />}>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/FindAProfessional" element={<FindAProfessional />} />
                <Route path="/ListingsScreen" element={<ListingsScreen />} />
                <Route path="/MyServiceOffering" element={<MyServiceOffering />} />
                <Route path="/LookForWork" element={<LookForWork />} />
                <Route path="/MyJobListing" element={<MyJobListing />} />
                <Route path="/EditJob" element={<AddJob />} />
                <Route path="/ExpertsScreen" element={<ExpertScreen />} />
                <Route path="/ApplicantsScreen" element={<ApplicantsScreen />} />
                <Route path="/JobInfo" element={<JobInfo />} />
                <Route path="/ProviderPublicProfile" element={<ProviderPublicProfile />} />
                <Route path="/ProviderPrivateProfile" element={<ProviderPrivateProfile />} />
                <Route path="/ClientPrivateProfile" element={<ClientPrivateProfile />} />
                <Route path="/ClientPublicProfile" element={<ClientPublicProfile />} />
                <Route path="/ReviewListScreen" element={<ReviewListScreen />} />
                <Route path="/ProfileEdit" element={<ProfileEdit />} />
                <Route path="/completedGigsList" element={<CompletedGigsListScreen />} />
                <Route path="/CompletedGigScreen" element={<CompletedGigScreen />} />
                <Route path="/showEstimateScreen" element={<ShowEstimateScreen />} />
                <Route path="/createEstimate" element={<CreateEstimate />} />
                <Route path="/lookForWork" element={<LookForWork />} />
                <Route path="/addService" element={<AddService />} />
                <Route path="/serviceInfo" element={<ServiceInfo />} />
                <Route path="/WalletScreen" element={<WalletScreen />} />
                <Route path="/Subscriptioninfo" element={<SubscriptionInfo />} />
              </Route>

              <Route path="feedback" element={<Feedback />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route
                path="*"
                element={<PageNotFound />}
              />
            </Routes>
          </div>
        } />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route element={<AuthHandler />}>
          <Route path="/chatRooms" element={<ChatDashboard />} >
            <Route path=":chatroomUUID/channel/:channelUUID" element={<ChatRoomScreen />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);

register();