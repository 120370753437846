import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import fetch from "../axios/manager";
import * as Loader from "../dialogs/loader/loaderop";
import showToast from "../dialogs/loader/toast";

const firebaseConfig = {
    apiKey: "AIzaSyABlVcjnhizhapFbosGiE9venGxXo7Ca3s",
    authDomain: "farmplete-34492.firebaseapp.com",
    projectId: "farmplete-34492",
    storageBucket: "farmplete-34492.appspot.com",
    messagingSenderId: "390041310176",
    appId: "1:390041310176:web:6b8cb53e238c10bc7e9185",
    measurementId: "G-EED0WWZ130",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const getFcmToken = async () => {
    try {
        return await getToken(messaging, {
            vapidKey: "BP8zW9BZ84_OsN6sF8uqNnpALhw_gDMtd7TcL9u1RXE2CNupN6y-KoIQrmio-r77TFFRqsDS9K11uOEBSPZDNjs",
        });
    }
    catch (e) {
        return null;
    }
};

export const subscribeToTopic = async (token, topic) => {
    Loader.show();
    const [, error] = await fetch({
        route: "/users/subscribeToTopic",
        requestType: "put",
        body: {
            fcmToken: token,
            topic: topic,
        }
    });

    Loader.hide();

    if (error != null) {
        showToast(error);
    }
};