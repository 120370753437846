import { configureStore } from "@reduxjs/toolkit";

import AlertDialogReducer from "./slices/alertdialog";
import chatroomsReducer from "./slices/chatrooms";
import joblistingReducer from "./slices/joblistings";
import notificationsReducer from "./slices/notifications";
import TopLoadingBarReducer from "./slices/toploadingbar";
import userReducer from "./slices/user";
import walletReducer from "./slices/wallet";

const store = configureStore({
    reducer: {
        alertDialogSlice: AlertDialogReducer,
        userSlice: userReducer,
        jobListingSlice: joblistingReducer,
        chatroomsSlice: chatroomsReducer,
        walletSlice: walletReducer,
        loadingBarSlice: TopLoadingBarReducer,
        notificationsSlice: notificationsReducer,
    },
});

export default store;