import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowForward } from "../../../assets/icons/dashboard/arrow-forward.svg";

const DashboardItem = ({ item }) => {
    return <Link to={item.to}>
        <div style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.05)" }} className="flex flex-row rounded-primary border-card-border border px-8 h-24 items-center hover:border-primary cursor-pointer transition-all duration-200 ease-in-out">
            <div className="flex flex-1 flex-row gap-6 items-center">
                <item.icon />
                <h1 className="text-2xl text-primary-dark font-semibold">{item.label}</h1>
            </div>
            {(item.notificationCount != null && item.notificationCount != 0) && <div className="flex gap-2 items-center mr-3">
                <div className="h-6 w-6 bg-primary aspect-square rounded-[50%] grid place-content-center">
                    <div className="text-white text-sm">{item.notificationCount}</div>
                </div>
                <div className="text-black text-md">New Messages</div>
            </div>}
            <ArrowForward />
        </div>
    </Link>;
};

export default DashboardItem;