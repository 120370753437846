import React from "react";

import { ReactComponent as MasterCard } from "../../../assets/icons/dashboard/mastercard.svg";
import { ReactComponent as Visa } from "../../../assets/icons/dashboard/visa.svg";

const CardItem = ({ item }) => {
    const Icon = item.provider === 'visa' ? Visa : MasterCard;

    return <div className="flex flex-row w-[100%] bg-[#F9F9F9] border-[#DDDDDD] border rounded-primary h-20 px-4 hover:bg-opacity-70 hover:border-primary transition-all ease-in-out duration-200 cursor-pointer">
        <div className="flex-grow flex flex-col self-stretch justify-center">
            <h1 className="text-darkGray font-medium text-md">Card Number</h1>
            <h1 className="text-darkGray font-semibold text-lg">{item.cardNumber}</h1>
        </div>
        <div className="self-stretch flex flex-col justify-center">
            <div className="h-11 aspect-square bg-[#EEEEEE] border-[#CACACA] rounded-[10px] w-[50px] p-2 border">
                <Icon className="aspect-square h-[100%] w-[100%] object-contain" height="100%" width="100%" />
            </div>
        </div>
    </div>;
};

export default CardItem;