import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";
import { setProgress } from "../../../redux/slices/toploadingbar";
import ExpertItem from "../components/expert-item";

const ExpertScreen = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const { listing } = location.state;

    const [experts, setExperts] = useState(null);

    const fetchExperts = async (offset, limit) => {
        if (offset == 0 && experts != null) {
            return experts;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `listings/listing/${listing.uuid}/experts/all`,
            requestType: "get",
            cacheKey: `experts-${listing.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const expertsData = result.res;
        setExperts(state => [
            ...(state ?? []),
            ...expertsData,
        ]);

        dispatcher(setProgress(100));
        Loader.hide();

        return expertsData;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-center">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20">
                        <h1 className="text-black font-semibold mb-10 self-start text-3xl mt-12">{listing.type}</h1>
                        <PaginatedScrollView onNotFound="No experts found in this category" pageRequest={fetchExperts} className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-start gap-5 p-10"
                            child={(item) => {
                                return (
                                    <ExpertItem key={item.uuid} profile={item.profile} reviews={item.reviews} title={`${item.profile.firstName}
                                     ${item.profile.lastName}`} path={"/serviceInfo"} state={{ service: item, isItMe: false }} />
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default ExpertScreen;