import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "new": {},
    "mine": {},
    "progress": {},
    "completed": {},
    "listings": {},
    "services": {},
};

const jobListingSlice = createSlice({
    name: 'jobListings',
    initialState: initialState,
    reducers: {
        addItem: (state, action) => {
            const { type, item } = action.payload;
            state[type][item.uuid] = item;
        },
        updateItem: (state, action) => {
            const { type, item } = action.payload;
            state[type][item.uuid] = {
                ...state[type][item.uuid],
                ...item,
            };
        },
        deleteItem: (state, action) => {
            const { type, item } = action.payload;

            if (state[type][item.uuid]) {
                delete state[type][item.uuid];
            }
        },
        addInnerItem: (state, action) => {
            const { type, item, distance } = action.payload;
            state[type][distance] ??= {};
            state[type][distance][item.uuid] = item;
        },
        updateInnerItem: (state, action) => {
            const { type, item, distance } = action.payload;

            state[type][distance][item.uuid] = item;
        },
        deleteInnerItem: (state, action) => {
            const { type, item, distance } = action.payload;
            if (state[type][distance] && state[type][distance][item.uuid]) {
                delete state[type][distance][item.uuid];
            }
        },
    }
});

export const { addItem, deleteItem, updateItem, addInnerItem, deleteInnerItem, updateInnerItem } = jobListingSlice.actions;
export default jobListingSlice.reducer;