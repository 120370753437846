import React, { useEffect } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Cache from "../../../cache/cachemanager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import usePageVisibility from "../../../global/hooks/page_visibility";
import goTo from "../../../global/methods/goto";
import { setProgress } from "../../../redux/slices/toploadingbar";
import { updateCompletion } from "../../../redux/slices/user";
import OutlinedButton from "../../homepage/components/outlinedbutton";

const ClientVerificationScreen = () => {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const userSlice = useSelector((state) => state.userSlice);

    usePageVisibility(handleBlur, handleFocus);

    function handleBlur() {
        console.log("page is out of focus");
    }

    async function handleFocus() {
        Loader.show();

        const [profileCompletionData, profileCompletionError] = await fetch({
            route: `/users/${user.uuid}/profileCompletion`,
            requestType: "get",
        });

        if (profileCompletionError != null) {
            return onError(profileCompletionError);
        }

        dispatcher(updateCompletion(profileCompletionData["res"]));

        Loader.hide();
    }

    const profileCompletion = userSlice.profileCompletion;
    const user = userSlice.user;

    console.log(profileCompletion);

    const steps = [
        {
            label: "Identity Verification - IDV",
        },
    ];

    useEffect(() => {
        if (((profileCompletion?.verificationPayment ?? -1) == 1) &&
            ((profileCompletion?.checkrVerification ?? -1) == 1) &&
            ((profileCompletion?.userVerification ?? -1) == 1) && (profileCompletion?.subscriptionId ?? -1) == -1) {
            navigate("/SubcriptionPickScreen");
            return;
        }

        finishCheckrForClient();

        if ((profileCompletion?.verificationPayment ?? -1) != 1) {
            onVerificationPaymentCompleted();
        }
    }, []);

    async function onVerificationPaymentCompleted() {
        Loader.show();

        const [, error] = await fetch({
            route: `users/profileCompletion/${user.uuid}`,
            requestType: "put",
            body: {
                verificationPayment: 1,
            }
        });

        if (error != null) {
            return onError(error);
        }

        await Cache.set("profileCompletionData", {
            ...profileCompletion,
            verificationPayment: 1,
        });
        dispatcher(updateCompletion({ verificationPayment: 1 }));
    }

    function finishCheckrForClient() {
        if ((profileCompletion?.checkrVerification ?? -1) != 1) {
            onCheckrCompleted();
        }
    }

    const onError = (error) => {
        dispatcher(setProgress(100));
        showToast(error);
        Loader.hide();
    };

    const startIDV = async () => {
        goTo(`https://idv.onceusa.com/${user.uuid}`);
    };

    const onCheckrCompleted = async () => {
        Loader.show();

        const [, error] = await fetch({
            route: `users/profileCompletion/${user.uuid}`,
            requestType: "put",
            body: {
                checkrVerification: 1,
            }
        });

        if (error != null) {
            return onError(error);
        }

        Loader.hide();

        await Cache.set("profileCompletionData", {
            ...profileCompletion,
            checkrVerification: 1,
        });
        dispatcher(updateCompletion({ checkrVerification: 1 }));
    };

    const onButtonClicked = () => {
        if ((profileCompletion?.userVerification ?? -1) == 0 || (profileCompletion?.userVerification ?? -1) == -1) {
            startIDV();
        }
        else if ((profileCompletion?.userVerification ?? -1) == 2) {
            showToast("Your request is under review");
        }
        else {
            navigate("/SubcriptionPickScreen");
        }
    };

    function getCurrentStep() {
        if ((profileCompletion?.isIdentityVerified ?? -1) == 1 ||
            (profileCompletion?.isIdentityVerified ?? -1) == 2) {
            return 0;
        }

        return 1;
    }

    function getButtonText() {
        if ((profileCompletion?.userVerification ?? -1) == 0 || (profileCompletion?.userVerification ?? -1) == -1) {
            return "Start Identity Verification";
        }
        else if ((profileCompletion?.userVerification ?? -1) == 2) {
            return "Under Review";
        }

        return "Buy Subscription";
    }

    return <div className="flex flex-col w-[100%] justify-center h-[80vh] items-center">
        <div className="flex flex-col mx-10 w-[400px] p-10 items-center justify-center border-[#EBEBEB] border rounded-primary bg-[#FAFAFA] gap-6 mb-10">
            <Stepper activeStep={getCurrentStep()}
                connectorStyleConfig={{
                    completedColor: "#099F44",
                    activeColor: "#099F44"
                }}
                styleConfig={{
                    activeBgColor: "#099F44",
                    completedBgColor: "#099F44",
                }} connectorStateColors={true}>
                {steps.map((item) => {
                    return <Step key={item.label} label={item.label} />;
                })}
            </Stepper>

            <OutlinedButton onClicked={() => onButtonClicked()} label={getButtonText()} extracss="primary-gradient text-white border-none w-[100%] h-11" />
        </div>
    </div>;
};

export default ClientVerificationScreen;