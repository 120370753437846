import dateFormat from "dateformat";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Checkmark } from "../../../assets/icons/chatrooms/checkmark.svg";
import { ReactComponent as DoubleCheckmark } from "../../../assets/icons/chatrooms/doublecheckmark.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import setProgress from "../../../dialogs/loader/loader";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";


const ChatRoomItem = ({ chatroomUUID, type }) => {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const item = useSelector((state) => state.chatroomsSlice[chatroomUUID]);
    const profile = item.otherProfile;

    const channels = Object.values(item.channels).filter((element) => type == "work" ? element.uuid != "-1" : element.uuid == "-1");

    const onItemClick = async (channel) => {
        if (channel.id != '-1') {
            Loader.show();
            dispatcher(setProgress(50));

            const [result, error] = await fetch({
                route: `chats/auth/channel/${channel.uuid}`,
                requestType: "get",
                cacheKey: `channel-${channel.uuid}`,
                cacheOptions: 'fromCache'
            });

            if (error != null) {
                onError(error);
                return;
            }

            dispatcher(setProgress(100));
            Loader.hide();

            navigate(`${item.uuid}/channel/${channel.uuid}`, {
                replace: location.pathname.includes("channel"),
                state: {
                    chatroomUUID: item.uuid,
                    channel: channel,
                    job: result.res?.job,
                    service: result.res?.service,
                }
            });
        }
        else {
            navigate(`${item.uuid}/channel/general`, {
                replace: location.pathname.includes("channel"),
                state: {
                    chatroomUUID: item.uuid,
                    channel: channel,
                }
            });
        }
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <>
        {channels.map((channel) => <Item key={`${item.uuid}-${channel.uuid}`}
            profile={profile} channel={channel} chatroomUUID={chatroomUUID}
            onClick={() => onItemClick(channel)} lastChat={channel?.chat} />)}
    </>;
};

const Item = ({ profile, lastChat, onClick, channel, chatroomUUID }) => {
    const notificationsSlice = useSelector(state => state.notificationsSlice);

    const notificationCount = notificationsSlice.notificationData[`${chatroomUUID}~${channel.uuid}`] ?? 0;

    return <div onClick={onClick} style={{ borderBottom: '1px solid #E6E6E6' }} className="flex flex-row px-4 gap-2 items-center justify-between h-[80px] transition-all ease-in-out duration-200 hover:bg-Gray85 cursor-pointer">
        <img className="h-[45px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${profile.profileImage}`} alt="profile" />
        <div className="flex-col flex-grow items-start justify-between">
            <h1 className="text-black text-md font-semibold">{`${channel.uuid != "-1" ? `${channel.name}: ` : ""} ${profile.firstName} ${profile.lastName}`}</h1>
            {lastChat && <div className="flex flex-row items-center gap-1 w-[100%]">
                {lastChat.isSentFromMe && (
                    <div>
                        {lastChat.readStatus == 0 ? <Checkmark className="w-[12px] mt-[2px] h-[100%] object-cover" style={{ filter: "invert(40%) sepia(90%) saturate(0%) hue-rotate(135deg) brightness(92%) contrast(69%)" }} />
                            : lastChat.readStatus == 1 ? <Checkmark className="w-[12px] mt-[2px] h-[100%] object-cover" />
                                : <DoubleCheckmark className="w-[15px] mt-[4px] h-[100%] object-cover" />}
                    </div>
                )}
                <h1 className="text-darkGray text-sm font-normal overflow-hidden line-clamp-1">{lastChat.estimate != null ? "Estimate" : lastChat.image != null ? "Image" : lastChat.chat}</h1>
            </div>}
        </div>

        {lastChat != null && <div className="flex flex-col justify-start items-end gap-1 flex-shrink-0">
            <h1 className="text-darkGray text-xs font-medium text-ellipsis line-clamp-1">{dateFormat(lastChat.createdAt, "h:mm tt")}</h1>
            {!lastChat.isSentFromMe && notificationCount != 0 && <div className="bg-[#115AA8] aspect-square h-5 rounded-[50%] grid place-items-center">
                <h1 className="text-white font-medium text-xs">{notificationCount}</h1>
            </div>}
        </div>}
    </div>;
};

export default ChatRoomItem;