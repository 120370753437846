import { useRef } from "react";
import React from "react";

import { ReactComponent as AddImage } from "../../../assets/icons/chatrooms/image.svg";
import { ReactComponent as SendButton } from "../../../assets/icons/chatrooms/send.svg";

const SendTextField = ({ onSubmitted, onImageAdd }) => {
    const textfieldRef = useRef();

    const submit = () => {
        onSubmitted(textfieldRef.current.value);

        textfieldRef.current.value = '';
    };

    return <div style={{ boxShadow: "6px 6px 40px 6px rgba(0,0,0,0.04)" }} className="z-[1] min-h-[50px] rounded-primary bg-white mx-5 mb-5 mr-10 px-2 pl-4 flex flex-row items-center">
        <input ref={textfieldRef} onKeyUp={(e) => {
            if (e.key === 'Enter') {
                submit();
            }
        }} placeholder="Type a Message" className="outline-none text-md font-medium flex-grow" type="text" />

        <div className="flex flex-row gap-4 h-[100%] w-[90px] items-center justify-end">
            <div onClick={onImageAdd} className="h-[100%] cursor-pointer flex items-center">
                <AddImage className="aspect-square h-7 w-7 object-contain" />
            </div>
            <div onClick={submit} className="h-[75%] primary-gradient cursor-pointer aspect-square rounded-primary grid place-content-center">
                <SendButton />
            </div>
        </div>
    </div>;
};

export default SendTextField;