import { useEffect, useRef, useState } from "react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Estimate } from "../../../assets/icons/chatrooms/estimate.svg";
import fetch from "../../../axios/manager";
import setProgress from "../../../dialogs/loader/loader";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import ProfileAvatar from "../../../global/components/profileavatar";
import { toFormData } from "../../../global/methods/toformdata";
import { addChat } from "../../../redux/slices/chatrooms";
import * as NotificationRedux from "../../../redux/slices/notifications";
import Chatbubble from "../components/chatbubble";
import SendTextField from "../components/sendtextfield";

const ChatRoomScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { chatroomUUID, channel, job, service } = location.state;

    const chatroom = useSelector((state) => state.chatroomsSlice[chatroomUUID]);
    const notificationsSlice = useSelector(state => state.notificationsSlice);
    const userSlice = useSelector((state) => state.userSlice);

    if (chatroom == null) {
        navigate(-1);

        return <></>;
    }

    const scrollableTargetRef = useRef(null);
    const scrollableContainerRef = useRef(null);

    const otherProfile = chatroom.otherProfile;
    const myProfile = userSlice.profile;
    const limit = 15;

    const [offset, setOffset] = useState(0);
    const [hasMore, sethasMore] = useState(true);
    const [otherProfileType, setOtherProfileType] = useState("");

    const filePicker = useRef();

    const dispatcher = useDispatch();

    const chats = chatroom?.chats == null ? [] : Object.values(chatroom?.chats[channel.uuid] ?? {}).reverse();

    useEffect(() => {
        setOffset(0);
        sethasMore(true);

        fetchChats(0);
        fetchOtherProfileType();

        initNotifications();
    }, [location]);

    useEffect(() => {
        if (offset == 0) return;

        const checkScrollable = () => {
            if (scrollableTargetRef.current.scrollHeight <= scrollableContainerRef.current.clientHeight && hasMore) {
                fetchChats(offset);
            }
        };

        checkScrollable();
    }, [offset]);

    function initNotifications() {
        const count = notificationsSlice.notificationData[`${chatroomUUID}~${channel.uuid}`] ?? 0;

        if (count != 0) {
            markReadAll();
        }
    }

    async function markReadAll() {
        const [, error] = await fetch({
            route: "/chats/auth/chats/updateAllReadStatus",
            requestType: "put",
            body: {
                chatroomUUID: chatroomUUID,
                channelUUID: channel.uuid,
                readStatus: 2,
                otherProfileUUID: chatroom.otherProfile.uuid,
            }
        });

        if (error != null) {
            showToast(error);
            return;
        }

        const oldNotificationCount = notificationsSlice.notificationData[`${chatroomUUID}~${channel.uuid}`] ?? 0;

        dispatcher(NotificationRedux.setNotifications({ chatroomUUID: chatroomUUID, channelUUID: channel.uuid, count: 0 }));

        if (oldNotificationCount > 0) {
            dispatcher(NotificationRedux.decrementNotificationCount());
        }
    }

    const fetchOtherProfileType = async () => {
        dispatcher(setProgress(50));
        Loader.show();

        const [response, error] = await fetch({
            route: `profiles/type/${otherProfile.uuid}`,
            requestType: "get",
            cacheKey: `profiles-type-${otherProfile.uuid}`,
            cacheOptions: 'fromCache'
        });

        if (error != null) {
            onError(error);
            return;
        }

        dispatcher(setProgress(100));
        Loader.hide();

        const type = response.res.type;

        setOtherProfileType(type);
    };

    const onProfileClick = async () => {
        if (otherProfileType == "provider") {
            navigate("/ProviderPublicProfile", {
                state: {
                    profile: otherProfile,
                }
            });
        }
        else {
            navigate("/ClientPublicProfile", {
                state: {
                    profile: otherProfile,
                }
            });
        }
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    const onSend = async ({ text, file, estimate }) => {
        if (text == null && file == null && estimate == null) return;

        setProgress(50);

        const body = {
            readStatus: "0",
            chatType: file != null
                ? "media"
                : estimate != null
                    ? "estimate"
                    : "text",
            chatroomUUID: chatroomUUID,
            channelUUID: channel.uuid,
            chat: text,
        };

        if (file != null) {
            body.image = file;
        }
        else if (estimate != null) {
            body.estimateUUID = estimate.uuid;
        }
        else {
            body.chat = text;
        }

        const [response, error] = await fetch({
            route: "chats/auth/chats",
            requestType: "post",
            body: toFormData(body),
        });

        setProgress(100);

        if (error != null) {
            return showToast(error);
        }

        dispatcher(addChat({
            chatroomUUID: chatroomUUID,
            channelUUID: channel.uuid,
            chat: response.res,
            last: true,
        }));

    };

    const pickImage = (e) => {
        if (e.target.files.length === 0) return;
        const file = e.target.files[0];

        onSend(null, file);
    };

    const fetchChats = async (offset) => {
        if (offset == 0 && chats.length != 0) {
            return chats;
        }

        const [response, error] = await fetch({
            route: `chats/auth/all/${chatroomUUID}`,
            requestType: "get",
            params: {
                offset: offset,
                limit: limit,
                sort: "DESC",
                channelUUID: channel.uuid,
            }
        });

        if (error != null) {
            showToast(error);
            return;
        }

        setOffset((state) => state + response.res.length);

        if (response.res.length < limit) {
            sethasMore(false);
        }

        for (let i = 0; i < response.res.length; i++) {
            dispatcher(addChat({
                chatroomUUID: chatroomUUID,
                chat: response.res[i],
                channelUUID: channel.uuid,
            }));
        }
    };

    const onChannelClicked = () => {
        if (job != null) {
            navigate("/JobInfo", {
                state: {
                    job: job,
                    isItMe: userSlice.user.userType != "provider",
                    label: userSlice.user.userType != "provider" ? "My" : "",
                }
            });
        }
        else {
            navigate("/serviceInfo", {
                state: {
                    service: service,
                    isItMe: userSlice.user.userType == "provider",
                }
            });
        }
    };

    return <div className="flex flex-col items-stretch w-[100%] h-[100vh]">
        <div className={`${channel.id == -1 ? "bg-white" : "bg-[#F1F1F1]"} h-[70px] shrink-0 px-4 pr-10 flex flex-row justify-between items-center`}>
            <div onClick={onProfileClick} className="flex flex-row gap-2 items-center cursor-pointer">
                <ProfileAvatar clickable={false} width='w-10' isItMe={false} profile={otherProfile} checkmark={false} photo={otherProfile.profileImage} />
                <h1 className="text-black text-lg font-semibold">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
            </div>
            {(userSlice.user.userType == "provider" && channel.id != -1) && <Link to="/createEstimate" state={{ job: job, service: service, chatroomUUID: chatroomUUID, channelUUID: channel.uuid, }}
                className="h-[60%] cursor-pointer primary-gradient aspect-square rounded-primary grid place-content-center">
                <Estimate className="h-7 2-7 aspect-square object-contain" />
            </Link>}
        </div>
        {channel.id != -1 && <div onClick={onChannelClicked} className="bg-white shrink-0 cursor-pointer h-[60px] flex flex-row items-center justify-start pl-4">
            <h1 className="text-black text-lg font-medium">{channel.name}</h1>
        </div>}
        <div ref={scrollableContainerRef} id="scrollable-target" className="px-5 grow w-[100%] flex flex-col-reverse overflow-y-scroll">
            <>
                {chats?.length == 0 && <div className='w-full h-full flex items-center justify-center'>
                    <h1>No chat here. Write something...</h1>
                </div>}
                <InfiniteScroll
                    next={() => fetchChats(offset)}
                    inverse={true}
                    hasMore={hasMore}
                    className="py-4"
                    scrollableTarget="scrollable-target"
                    dataLength={chats.length}>
                    <div ref={scrollableTargetRef} className="h-full">
                        {chats.map((chat) => <Chatbubble
                            otherProfileType={otherProfileType}
                            key={chat.uuid}
                            chat={chat}
                            myProfile={myProfile}
                            otherProfile={otherProfile}
                            job={job}
                            service={service}
                            channelUUID={channel.uuid}
                            chatroomUUID={chatroomUUID}
                        />)}
                    </div>
                </InfiniteScroll>
            </>
        </div>
        <SendTextField onSubmitted={(text) => onSend({ text: text })} onImageAdd={() => filePicker.current.click()} />
        <input ref={filePicker} style={{ display: "none" }} type="file" accept="image/*" onChange={pickImage} />
    </div>;
};

export default ChatRoomScreen;