import { FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Button from "../../global/components/buttton";
import TextField from "../../global/components/textfield";
import { states } from "../../global/variables/states";
import * as Alertdialog from "../../redux/slices/alertdialog";

const AlertDialogSsnBody = ({ onSubmit }) => {
    const initialValues = {
        ssn: "",
        state: "AL",
        city: "",
    };

    const dispatcher = useDispatch();

    const formSchema = Yup.object().shape({
        ssn: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            dispatcher(Alertdialog.hide());

            onSubmit(values);
        },
        validateOnBlur: true,
        validationSchema: formSchema,
    });

    return (
        <div className="flex flex-col justify-between items-stretch py-10 px-14 w-[500px]">
            <h2 className="text-center md:text-4xl text-3xl uppercase font-light leading-normal text-darkGray mb-9">Verification Details
            </h2>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-2 items-stretch w-[100%]">
                        <div className="flex flex-col">
                            <TextField
                                error={
                                    formik.errors.ssn != null && formik.touched.phoneNumber
                                }
                                size="h-12"
                                type="text"
                                name="ssn"
                                placeholder="Social Security Number"
                            />
                            <p className="text-black text-xs">We do not store your SSN. It is forwarded to Checkr via encrypted channel to proceed with the verification.</p>
                        </div>

                        <select className="bg-textfield-back h-12 text-bl border-textfield-stroke border w-[100%] rounded-[10px] px-5" name="state" value={formik.values.state} onChange={formik.handleChange}>
                            {Object.keys(states).map(state => <option key={state} value={states[state]} >{state}</option>)}
                        </select>

                        <TextField
                            error={
                                formik.errors.city != null && formik.touched.phoneNumber
                            }
                            size="h-12"
                            type="text"
                            name="city"
                            placeholder="Work Location City"
                        />

                        <Button child="Continue to Checkr" extraCss="my-8 mb-5 h-11" />
                    </div>
                </form>
            </FormikProvider>
        </div>
    );
};

export default AlertDialogSsnBody;
