import 'swiper/css';
import 'swiper/css/navigation';

import dateFormat from 'dateformat';
import { React, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as AcIcon } from '../../../assets/icons/homescreen/ac-repair.svg';
import { ReactComponent as BathRoomCleanIcon } from '../../../assets/icons/homescreen/bathroom-cleaning.svg';
import { ReactComponent as CalenderIcon } from "../../../assets/icons/homescreen/calender-ixon.svg";
import { ReactComponent as FarmpleteIcon } from "../../../assets/icons/homescreen/farmplete-icon.svg";
import { ReactComponent as FridgeIcon } from '../../../assets/icons/homescreen/fried-repair.svg';
import { ReactComponent as HomeCleaningIcon } from '../../../assets/icons/homescreen/home-cleaning.svg';
import { ReactComponent as HomePaintindIcon } from '../../../assets/icons/homescreen/home-painting.svg';
import { ReactComponent as IntegratedIcon } from '../../../assets/icons/homescreen/integrated-pest.svg';
import { ReactComponent as MarbleIcon } from '../../../assets/icons/homescreen/marble-polish.svg';
import { ReactComponent as PestControlIcon } from '../../../assets/icons/homescreen/pest-control.svg';
import { ReactComponent as SanitizationIcon } from '../../../assets/icons/homescreen/sanitization.svg';
import { ReactComponent as RatingIcon } from '../../../assets/icons/homescreen/star-fill.svg';
import { ReactComponent as UserIcon } from "../../../assets/icons/homescreen/user-icon.svg";
import { ReactComponent as WashingmachIcon } from '../../../assets/icons/homescreen/washingmachine-repair.svg';
import ReviewAuthorImage from '../../../assets/images/author-img1.png';
import ReviewAuthorImage1 from '../../../assets/images/author-img2.png';
import ReviewAuthorImage2 from '../../../assets/images/author-img3.png';
import CarpetCleaning2 from "../../../assets/images/carpet-cleaning-2.png";
import CarpetCleaning from "../../../assets/images/carpet-cleaning.png";
import BlogImage from '../../../assets/images/homescreen/blog.png';
import MiniService from "../../../assets/images/mini-service.png";
import SofaCleaning from "../../../assets/images/sofa-cleaning.png";
import SuperSaver from "../../../assets/images/super-saver.png";
import OwnerImg from '../../../assets/images/usertypes/client.png';
import EnterrpiseImg from '../../../assets/images/usertypes/enterprise.png';
import ProviderImg from '../../../assets/images/usertypes/provider.png';
import Footer from '../../../global/components/footer';
import OutlinedButton from '../components/outlinedbutton';


// Create an array of Services data objects
const serviceItems = [
    {
        title: "Home Cleaning",
        image: <HomeCleaningIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Home Painting",
        image: <HomePaintindIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Pest Control",
        image: <PestControlIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Marble Polish",
        image: <MarbleIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Sanitization",
        image: <SanitizationIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "AC Service and Repair",
        image: <AcIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Fridge Repairing",
        image: <FridgeIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Washing Machine Repair",
        image: <WashingmachIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Bathroom Deep Cleaning",
        image: <BathRoomCleanIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
    {
        title: "Integrated Pest",
        image: <IntegratedIcon />,
        altText: "home-cleaing-icon",
        url: "/",
    },
];

const reviews = [
    {
        "review": "I recently hired Amazing Cleaning Services to tackle a big cleaning project at my home, and I must say I was thoroughly impressed with their professionalism and attention to detail. The team arrived on time, equipped with all the necessary tools and cleaning supplies. They worked diligently and efficiently, ensuring that every nook and cranny was spotless by the time they were done. Not only did they meet my expectations, but they exceeded them. I highly recommend their services to anyone in need of top-quality cleaning!",
        "name": "John Doe",
        "date": Date.now(),
        "reviewStars": 5,
        "image": ReviewAuthorImage2,
        "serviceName": "Amazing Cleaning Services"
    },
    {
        "review": "I recently had the pleasure of working with Professional Painting Solutions, and I couldn't be happier with the results. From the initial consultation to the final coat of paint, the entire process was smooth and hassle-free. The team was incredibly skilled and attentive to detail, ensuring that every stroke of the brush was perfect. Not only did they transform the look of my home, but they also completed the project ahead of schedule. I would definitely recommend Professional Painting Solutions to anyone looking for high-quality painting services.",
        "name": "Jane Smith",
        "date": Date.now(),
        "reviewStars": 4,
        "image": ReviewAuthorImage,
        "serviceName": "Professional Painting Solutions"
    },
    {
        "review": "I recently had the pleasure of working with Reliable Plumbing Services for a plumbing issue in my home, and I must say I was impressed with their prompt response and excellent service. The plumber arrived on time and quickly diagnosed the problem. He explained the issue clearly and provided me with a range of solutions to choose from. Throughout the repair process, he was professional, courteous, and efficient. I was very satisfied with the quality of workmanship and the fair pricing. I highly recommend Reliable Plumbing Services to anyone in need of plumbing assistance.",
        "name": "Alice Johnson",
        "date": Date.now(),
        "reviewStars": 3,
        "image": ReviewAuthorImage2,
        "serviceName": "Reliable Plumbing Services"
    },
    {
        "review": "I recently hired Top-Notch Roofing Solutions to repair a leak in my roof, and I am extremely satisfied with the service I received. From the moment I contacted them, they were responsive and attentive to my needs. The roofing team arrived on time and worked diligently to identify and fix the problem. They were skilled, efficient, and respectful of my property. The quality of their workmanship was top-notch, and they completed the job in a timely manner. I would highly recommend Top-Notch Roofing Solutions to anyone in need of roofing repairs or installations.",
        "name": "Michael Brown",
        "date": Date.now(),
        "reviewStars": 5,
        "image": ReviewAuthorImage1,
        "serviceName": "Top-Notch Roofing Solutions"
    },
    {
        "review": "I recently had the pleasure of working with Efficient Electrical Repairs to fix some electrical issues in my home, and I was very impressed with their service. The electrician arrived on time and was friendly, professional, and knowledgeable. He quickly identified the problem and explained the repair process to me in detail. Throughout the job, he was thorough and efficient, ensuring that everything was working perfectly before he left. I was very satisfied with the quality of work and the reasonable pricing. I would definitely recommend Efficient Electrical Repairs to anyone in need of electrical services.",
        "name": "Emily Wilson",
        "date": Date.now(),
        "reviewStars": 4,
        "image": ReviewAuthorImage,
        "serviceName": "Efficient Electrical Repairs"
    }
];

const blog = {
    "title": "Transform Your Terrace or Balcony Tips for a Better Home",
    "description": "Most Indian households take their terrace or a balcony for granted while being unaware of the various things we can transform them into! Let's see how It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages.",
    "date": Date.now(),

};

const services = [
    {
        "image": SofaCleaning,
        "title": 'Sofa Cleaning',
        "stars": "4.56",
        "reviewCount": "126.7k",
        "price": 129,
    },
    {
        "image": CarpetCleaning,
        "title": 'Carpet Cleaning',
        "stars": "4.78",
        "reviewCount": "126.7k",
        "price": 49,
    },
    {
        "image": SofaCleaning,
        "title": 'Sofa Cleaning',
        "stars": "4.56",
        "reviewCount": "126.7k",
        "price": 129,
    },
    {
        "image": MiniService,
        "title": 'Mini Services',
        "stars": "4.89",
        "reviewCount": "126.7k",
        "price": 99,
    },
    {
        "image": SuperSaver,
        "title": 'Super Saver Deals',
        "stars": "4.52",
        "reviewCount": "126.7k",
        "price": 59,
    },
    {
        "image": CarpetCleaning2,
        "title": 'Carpet Cleaning',
        "stars": "4.83",
        "reviewCount": "126.7k",
        "price": 79,
    },
    {
        "image": MiniService,
        "title": 'Mini Services',
        "stars": "4.89",
        "reviewCount": "126.7k",
        "price": 99,
    },
];

export default function Home() {
    const isMobileView = window.innerWidth < 640;

    useEffect(() => {
        Notification.requestPermission();
    }, []);

    return (
        <>
            <main>
                <section className='hero-banner'>
                    <div className="grid grid-cols-3 max-md:grid-cols-1">
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center">
                            <img loading='lazy' className='max-md:object-top' src={OwnerImg} alt="Owner" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'>Home</span>Owner <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center divider-left-custom">
                            <img loading='lazy' className='max-md:object-top' src={ProviderImg} alt="Provider" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'>Service</span>Provider <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                        <div className="image-container h-[740px] max-md:h-[400px] flex items-end justify-center divider-left-custom">
                            <img loading='lazy' className='max-md:object-top' src={EnterrpiseImg} alt="Enterprise" />
                            <h2 className='text-center text-white py-6 font-bold xl:text-5xl text-4xl uppercase leading-normal grid mb-[60px]'><span className='xl:text-3xl text-2xl font-light'>represent</span>an Enterprise <span className='w-[100px] h-[4px] bg-white mt-4 mx-auto'></span></h2>

                        </div>
                    </div>
                </section>

                <section className='our-services md:py-28 py-14 windsand-gradient'>
                    <div className="container mx-auto">
                        <h2 className='text-center md:text-5xl text-4xl uppercase font-light leading-normal text-darkGray'>Our <span className='font-bold'>Services</span></h2>
                        <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 md:gap-7 gap-5 md:pt-28 pt-14">
                            {serviceItems.map((service, index) => (
                                <div className="bg-white hover:border-primary transition-all duration-200 ease-in-out flex items-center justify-center border border-Gainsboro rounded-secondary p-7 text-center shadow-[0_10px_10px_0px_rgba(0,0,0,0.03)]" key={index}>
                                    <Link to={service.url}>
                                        <div className="text-center w-[64px] h-[64px] mx-auto">
                                            {service.image}
                                        </div>
                                        <h4 className="mt-7 text-darkGray font-bold text-xl capitalize">{service.title}</h4>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className='bg-white md:py-28 py-14'>
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="md:w-1/2">
                                <h2 className='text-start md:text-5xl text-4xl uppercase font-light leading-normal text-darkGray'>Customer <span className='font-bold'>Reviews</span></h2>
                            </div>
                            <div className="md:w-1/2 flex justify-end items-center">
                                <div className="min-w-[120px] relative flex items-center justify-between reviewNavigation" id='reviewNavigation'>
                                    <div className="swiper-button-prev border border-Gray78 rounded-[10px]"></div>
                                    <div className="swiper-button-next border border-Gray78 rounded-[10px]"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pt-14 reviewslider'>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={10}
                            centeredSlides={!isMobileView}
                            slidesPerView={1}
                            breakpoints={{
                                750: {
                                    slidesPerView: 2,
                                    slidesOffsetBefore: 0,
                                },
                                1100: {
                                    slidesPerView: 3,
                                    slidesOffsetBefore: 0,
                                },
                                1400: {
                                    slidesPerView: 4,
                                    slidesOffsetBefore: 0,
                                },
                            }}
                            loop={true}
                            navigation={{
                                nextEl: '#reviewNavigation .swiper-button-next',
                                prevEl: '#reviewNavigation .swiper-button-prev'
                            }}
                        >
                            {
                                reviews.map((review) => {
                                    const items = [];
                                    for (let i = 0; i < review['reviewStars']; i++) {
                                        items.push(<RatingIcon key={i} />);
                                    }

                                    return (
                                        <SwiperSlide key={review.uuid} className='p-3 slider-item'>
                                            <div className="shadow-[0_4px_10px_0px_rgba(0,0,0,0.12)] rounded-[12px] p-7 text-center">
                                                <p className='text-base font-normal font-darkBrown mb-6'>{`“${review["review"]}”`}</p>
                                                <div className='w-[80px] min-h-[80px] mx-auto'>
                                                    <img loading='lazy' src={review['image']} alt="Author Image" className='w-full h-full object-cover rounded-[10px]' />
                                                </div>
                                                <div className="flex flex-row gap-1 my-3 w-[100%] justify-center">
                                                    {items}
                                                </div>

                                                <h1 className='text-xl font-semibold'>{review["name"]}</h1>

                                                <div className='flex flex-row justify-center items-center gap-5 mt-2 max-lg:flex-col max-lg:gap-1'>
                                                    <div className='flex flex-row items-center gap-1'>
                                                        <CalenderIcon />
                                                        <h1>{dateFormat(review["date"], "d mmm, yyyy")}</h1>
                                                    </div>
                                                    <div className='flex flex-row items-center gap-1'>
                                                        <UserIcon />
                                                        <h1>{review["serviceName"]}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }

                        </Swiper>
                    </div>
                </section>

                <section className='overflow-hidden'>
                    <div className='grid lg:grid-cols-2 items-start mb-20 justify-stretch'>
                        <div className='h-full lg:max-h-full max-h-[450px] '>
                            <img loading='lazy' className='object-cover lg:h-[90%]' width="100%" height="100%" src={BlogImage} alt='blog image' />
                        </div>
                        <div className='relative flex flex-col justify-between bg-primary h-[100%] p-24'>
                            <div className='absolute right-0 xl:h-[100%] max-lg:h-[80%]'>
                                <FarmpleteIcon className='opacity-[0.12] h-[100%] w-[100%] object-cover' />
                            </div>

                            <h1 className='font-light text-white text-5xl'>OUR</h1>
                            <h1 className='font-semibold text-white text-5xl'>LATEST BLOG</h1>

                            <div className='mt-10'>
                                <h1 className='font-semibold text-white text-2xl'>{blog["title"]}</h1>
                            </div>

                            <div className='my-6 flex flex-row items-center gap-3'>
                                <CalenderIcon className='filter-white' />
                                <h1 className='text-white'>{dateFormat(blog["date"], "d mmm, yyyy")}</h1>
                            </div>

                            <div className='w-[100px] h-1 rounded-primary bg-white'></div>

                            <div className='mt-10 xl:mr-20'>
                                <p className='text-white text-lg font-normal'>{blog["description"]}</p>
                            </div>

                            <div className='mt-10'>
                                <OutlinedButton normal="white" extracss="border-white p-3 px-5 text-white hover:text-primary hover:bg-white" focused="primary" label="VIEW MORE" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container mx-auto mb-10">
                        <div className="flex">
                            <div className="md:w-1/2">
                                <h2 className='text-start md:text-5xl text-4xl font-light leading-normal text-darkGray'>OUR <span className='font-bold'>PRICING</span></h2>
                            </div>
                            <div className="md:w-1/2 flex justify-end items-center">
                                <div className="min-w-[120px] relative flex items-center justify-between reviewNavigation" id='reviewNavigation'>
                                    <div className="swiper-button-prev border border-Gray78 rounded-[10px]"></div>
                                    <div className="swiper-button-next border border-Gray78 rounded-[10px]"></div>
                                </div>
                            </div>
                        </div>
                        <div className='py-5 serviceSlider'>
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={20}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 5,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                    900: {
                                        slidesPerView: 3,
                                    },
                                    600: {
                                        slidesPerView: 2,
                                    },
                                }}
                                loop
                                navigation={{
                                    nextEl: '#serviceNavigation .swiper-button-next',
                                    prevEl: '#serviceNavigation .swiper-button-prev'
                                }}
                            >
                                {
                                    services.map((service) => {
                                        return (
                                            <SwiperSlide key={service.uuid} className='p-3 service-slider-item w-[100%]'>
                                                <div className="rounded-primary text-start justify-start flex flex-col">
                                                    <div>
                                                        <div className='w-[100%] mx-auto'>
                                                            <img loading='lazy' src={service['image']} alt="Author Image" className='w-full h-full object-cover rounded-[10px]' />
                                                        </div>

                                                        <h1 className='text-xl font-semibold pt-2 line-clamp-1'>{service["title"]}</h1>

                                                        <div className='flex flex-row items-center gap-1'>
                                                            <RatingIcon className='filter-grey h-[12px]' />
                                                            <h4 className='text-darkGray font-medium text-xs text-opacity-60'>{`${service['stars']}   (${service['reviewCount']})`}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <OutlinedButton extracss="flex px-4 py-2 text-primary border-primary hover:bg-primary hover:text-white" label={`$${service["price"]}`} />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </section>
                <Footer />
            </main >
        </>
    );
}
