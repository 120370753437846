import { FieldArray, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";

import fetch from "../../axios/manager";
import * as Cache from "../../cache/cachemanager";
import * as Jwt from "../../cache/jwt";
import Button from "../../global/components/buttton";
import TextField from "../../global/components/textfield";
import { getFcmToken, subscribeToTopic } from "../../integrations/firebaseconfig";
import * as Alertdialog from "../../redux/slices/alertdialog";
import { updateCompletion, updateProfile, updateUser } from "../../redux/slices/user";
import * as Loader from "../loader/loaderop";

const OtpDialog = ({ email }) => {
    const initialValues = {
        "otp": null,
    };

    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const [resendText, setResendText] = useState("Resend");
    const [otpAttempts, setOtpAttempts] = useState(1);
    const timer = useTimer({ autoStart: false, expiryTimestamp: new Date().getTime() + 60 });

    useEffect(() => {
        initOtpAttmpts();
    }, []);

    const initOtpAttmpts = async () => {
        const attempts = await Cache.get("OtpAttempts");

        if (attempts != null) {
            setOtpAttempts(parseInt(attempts) + 1);

            Cache.set("OtpAttempts", parseInt(attempts) + 1);
        }
        else {
            Cache.set("otpAttempts", 1);
        }
    };

    const onSubmit = async (values) => {
        Loader.show();

        const [loginData, loginError, status] = await fetch({
            route: `/users/login`,
            requestType: 'post',
            body: {
                otp: values.otp.join(''),
                email: email,
            }
        });

        Loader.hide();

        if (loginError != null) {
            dispatcher(Alertdialog.hide());

            if (status == 401) {
                Cache.removeAll();

                dispatcher(Alertdialog.hide());
                navigate("/", { replace: true });
                window.location.reload();
            }

            if (status == 404) {
                navigate("/pickUserTypeScreen", {
                    state: { email: email },
                    replace: true,
                });
            }

            return;
        }

        await Jwt.setToken({ jwt: loginData.token });

        const loginStatus = loginData.status;

        Loader.hide();

        const user = loginData.user;

        if (user != null) {
            await Cache.set("userData", user);
            dispatcher(updateUser(user));

            // FCM
            const token = await getFcmToken();

            if (token != null) {
                await subscribeToTopic(token, `user-general~${user.uuid}`);
            }
        }

        if (loginStatus == 405) {
            const user = loginData.user;

            const profileCompletionData = loginData.profileCompletion;

            if (profileCompletionData != null) {
                dispatcher(updateCompletion(loginData['profileCompletion']));
            }

            const profile = loginData['profile'];

            dispatcher(updateProfile(profile));

            dispatcher(Alertdialog.hide());

            if (user.userType == "provider") {
                navigate("/ProviderVerificationScreen");
            }
            else {
                navigate("/ClientVerificationScreen");
            }

            return;
        }
        else if (loginStatus == 406) {
            dispatcher(Alertdialog.hide());

            navigate("/ProfileEdit", {
                state: { firstTime: true },
            });

            return;
        }

        await Cache.set("profileData", loginData.profile);
        await Cache.set("profileCompletion", loginData.profileCompletion);

        dispatcher(updateCompletion(loginData.profileCompletion));

        dispatcher(updateProfile(loginData.profile));

        dispatcher(Alertdialog.hide());
        Loader.hide();

        navigate("/dashboard");
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
    });

    const onResendClick = async () => {
        if (timer.isRunning) return;

        const blocked = await Cache.get("blocked");
        if (blocked != null && (Date.now() - blocked) / (1000 * 60 * 60) < 1) {
            setResendText("Blocked");
            return;
        }

        const attempts = await Cache.get("OtpAttempts");

        if (attempts != null) {
            setOtpAttempts(parseInt(attempts) + 1);

            Cache.set("OtpAttempts", parseInt(attempts) + 1);
        }
        else {
            setOtpAttempts((state) => state + 1);
            Cache.set("OtpAttempts", otpAttempts + 1);
        }

        if (otpAttempts >= 5) {
            setResendText("Blocked");

            await Cache.set("blocked", Date.now());
        }
        else {
            resendOtp();
        }
    };

    async function resendOtp() {
        const [, error] = await fetch({
            route: "users/sendOtp",
            body: {
                email: email,
                register: false,
            },
            requestType: "post",
        });

        if (error != null) return;

        timer.restart(new Date().getTime() + 60000);
    }

    return <div className="flex flex-col justify-between items-center py-10 px-14 w-[500px]">
        <h2 className='text-center md:text-4xl text-3xl uppercase font-light leading-normal text-darkGray mb-9'>OTP <span className='font-bold'>Verification</span></h2>

        <h4 className="text-center px-4 text-md font-medium">Enter the verification code we just sent to your email {email}.</h4>

        <FormikProvider value={formik}>
            <form autoComplete="off" autoFocus className="flex flex-col w-[100%]" onSubmit={formik.handleSubmit}>
                <div className="flex flex-row gap-4 self-center mt-10">
                    <FieldArray name="otp" render={() =>
                        [...Array(5)].map((_, index) =>
                            <TextField id={`otp-[${index}]`} pattern="\d*" key={`otp[${index}]`} onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(`otp[${index}]`, value);
                                if (value == "") {
                                    if (index != 0) {
                                        document.getElementById(`otp-[${index - 1}]`).focus();
                                    }
                                    else { return; }
                                }
                                else {
                                    if (index == 4) return;
                                    document.getElementById(`otp-[${index + 1}]`).focus();
                                }
                            }}
                                maxLength="1" name={`otp[${index}]`} type="number" size="h-11 w-12" padding={false} contentPadding="px-0 text-center" extraCss="text-lg " />
                        )
                    }>
                    </FieldArray>
                </div>
                <h4 className="text-center text-md font-medium mt-6">Didn&lsquo;t Receive Code ?</h4>
                <h4 onClick={() => onResendClick()} className="text-center text-md cursor-pointer font-medium underline">{!timer.isRunning ? resendText : `Resend in ${timer.seconds} seconds`}</h4>

                <Button child="Submit" extraCss="my-4 h-11" />
            </form>
        </FormikProvider>

    </div>;
};

export default OtpDialog;