import dateFormat from 'dateformat';
import React from "react";
import { useSelector } from "react-redux";

import { ReactComponent as Calender } from "../../../assets/icons/dashboard/calender.svg";
import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { ReactComponent as StarFilled } from "../../../assets/icons/homescreen/star-fill.svg";
import { domain } from "../../../axios/adapter";


const CompletedGigItem = ({ item }) => {
    const userSlice = useSelector((state) => state.userSlice);

    const user = userSlice.user;
    const myProfile = userSlice.profile;

    const otherProfile = user.userType === 'provider' ? item.clientProfile : item.providerProfile;
    const review = user.userType === 'provider' ? item.clientReview : item.providerReview;

    const highlighedStars = [];
    const disabledStars = [];
    if (review != null) {
        for (let i = 0; i < review.givenStars; i++) {
            highlighedStars.push(StarFilled);
        }
        for (let i = 0; i < 5 - review.givenStars; i++) {
            disabledStars.push(StarFilled);
        }
    }

    const address = item.job == null ? `${myProfile.location}, ${myProfile.landmark}, ${myProfile.state}`
        : `${item.job.address}, ${item.job.city}, ${item.job.state}`;

    const gig = item?.job ?? item?.service;

    return <div style={{ boxShadow: "6px 6px 8px 6px rgba(0,0,0,0.03)" }} className="flex flex-col border hover:border-primary transition-all duration-200 cursor-pointer ease-in-out gap-2 border-card-border rounded-primary bg-white w-[100%]">
        <div className="flex flex-col p-3">
            <div className="flex flex-row gap-4 items-center w-[100%]">
                <div className="h-32">
                    <img className="object-cover w-[100%] h-[100%] aspect-square rounded-secondary" src={`${domain}/static/${gig.images[0]}`} alt="service" />
                </div>
                <div className="flex-grow flex flex-col gap-2 items-start justify-around">
                    <h1 className="text-black font-semibold text-lg line-clamp-2">{gig.title}</h1>
                    <div className="flex flex-row justify-start items-start gap-2">
                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2">{address}</h1>
                    </div>
                    <div className="flex flex-row justify-start items-start gap-2">
                        <Calender className="h-4 w-6 mt-[1px]" height="100%" width="100%" />
                        <h1 className="text-lightGray text-sm mr-5 line-clamp-1">{dateFormat(item.createdAt, "d mmm, yyyy | h:mmtt")}</h1>
                    </div>
                </div>
            </div>

            {review != null && <div className="flex flex-row mb-1 mt-3 gap-3">
                <img className="h-[50px] aspect-square rounded-[50%] object-cover" src={`${domain}/static/${otherProfile.profileImage}`} alt="profile" />
                <div className="flex flex-col flex-grow">
                    <h1 className="text-black font-semibold text-md line-clamp-2">{`${otherProfile.firstName} ${otherProfile.lastName}`}</h1>
                    <div className="flex flex-row justify-start items-center flex-nowrap">
                        {highlighedStars.map((Item, index) => <Item key={index} />)}
                        {disabledStars.map((Item, index) => <Item key={index} style={{ filter: "invert(76%) sepia(70%) saturate(17%) hue-rotate(358deg) brightness(184%) contrast(84%)" }} />)}
                    </div>
                </div>
            </div>}

            {review != null && <p className="text-sm font-medium">{review.review}</p>}
        </div>
        {review != null && review.images != null && <div>
            <div className="h-[7px] bg-darkGray bg-opacity-5 my-4 mt-0" />

            <div className="p-4 pt-0 flex flex-row flex-wrap gap-2">
                {review.images.map((image, index) =>
                    <img key={index} className="h-[80px] w-[80px] rounded-[5px] object-cover" src={`${domain}/static/${image}`} alt={`pic-${index}`} />
                )}
            </div>
        </div>}
    </div>;
};

export default CompletedGigItem;