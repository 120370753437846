import { createSlice } from "@reduxjs/toolkit";

import image1 from "../../assets/images/author-img1.png";
import image2 from "../../assets/images/author-img2.png";
import image3 from "../../assets/images/author-img3.png";

const initialState = {
    "balance": "1099",
    "cards": [
        {
            "provider": "visa",
            "cardNumber": "3454232165677898",
            "fullName": "Roy Ray",
            "cvv": "303",
        },
        {
            "provider": "mastercard",
            "cardNumber": "3454232165677898",
            "fullName": "Roy Ray",
            "cvv": "167",
        },
        {
            "provider": "visa",
            "cardNumber": "3454232165677898",
            "fullName": "Roy Ray",
            "cvv": "121",
        },
    ],
    "transactions": [
        {
            "profileImage": image2,
            "paidBy": "Christine Stephens",
            "amount": "199",
            "date": Date.now(),
        },
        {
            "profileImage": image1,
            "paidBy": "Nellie Butler",
            "amount": "299",
            "date": Date.now(),
        },
        {
            "profileImage": image3,
            "paidBy": "Frederick Holt",
            "amount": "349",
            "date": Date.now(),
        },
        {
            "profileImage": image2,
            "paidBy": "Helen Evans",
            "amount": "129",
            "date": Date.now(),
        },
        {
            "profileImage": image1,
            "paidBy": "Josephine Wilkins",
            "amount": "149",
            "date": Date.now(),
        },
    ],
};

const walletSlice = createSlice({
    name: "WalletSlice",
    initialState: initialState,
    reducers: {
        updateBalance: (state, action) => {
            state.balance = action.payload.balance;
        },
        addCard: (state, action) => {
            state.cards.push(action.payload.card);
        },
        removeCard: (state, action) => {
            state.cards.splice(action.payload.index, 1);
        },
        addTransaction: (state, action) => {
            state.transactions.push(action.payload.tranasaction);
        }
    }
});

export const { updateBalance, addCard, removeCard, addTransaction } = walletSlice.actions;
export default walletSlice.reducer;