import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as CheckMark } from "../../assets/icons/homescreen/checkmark.svg";
import { hide } from "../../redux/slices/alertdialog";

const LimitByDistance = ({ initialDistance, onChange }) => {
    const [distance, setDistance] = useState(initialDistance);
    const dispatcher = useDispatch();

    const items = [20, 25, 30, 35, 40, 45, 50, 55, 60];

    return <div className="flex flex-col justify-between items-center py-7 px-10">
        <h2 className='text-center text-xl uppercase font-semibold leading-normal text-darkGray mb-5 w-[25rem]'>Search By Distance</h2>
        {items.map((item, index) =>
            <div className={`flex flex-row rounded-primary hover:bg-Gray85 h-10 justify-center my-[2px] items-center relative cursor-pointer w-full
                 ${distance == item ? 'bg-Gray78' : ''}`} onClick={() => {
                    onChange(item);
                    setDistance(item);
                    setTimeout(() => {
                        dispatcher(hide());
                    }, 100);
                }} key={index}>
                <h1 className="text-black text-lg py-2 font-medium">{`${item} Miles`}</h1>
                {item == distance && <div className="bg-primary w-[20px] h-[20px] rounded-[50%] aspect-square absolute right-4 flex justify-center items-center">
                    <CheckMark className="h-[60%] w-[60%]" height="100%" width="100%" />
                </div>}
            </div>)}
    </div>;
};

export default LimitByDistance;