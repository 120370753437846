import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Location } from "../../../assets/icons/dashboard/location.svg";
import { domain } from "../../../axios/adapter";
import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import { setProgress } from "../../../redux/slices/toploadingbar";
import StaggeredListView from "../../profile/components/staggered_listview";
import RatingAndMessages from "../components/ratingAndMessages";

const ClientPublicProfile = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { profile } = location.state;

    const [completedGigs, setCompletedGigs] = useState({});

    const fetchCompletedGigs = async (offset, limit) => {
        if (offset === 0 && Object.keys(completedGigs).length !== 0) {
            return Object.values(completedGigs);
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `completedGigs/all/${profile.uuid}`,
            requestType: "get",
            cacheKey: `CompletedGigs-${profile.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const data = result.res;
        const gigs = {};

        for (let i = 0; i < data.length; i++) {
            gigs[data[i].uuid] = data[i];
        }

        setCompletedGigs({
            ...completedGigs,
            ...gigs,
        });

        dispatcher(setProgress(100));
        Loader.hide();

        return Object.values(gigs);
    };

    async function onMessage() {
        Loader.show();


    }

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="mx-10">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20 flex flex-col">
                        <h1 className="text-black font-semibold mb-10 text-3xl mt-12">Profile</h1>
                        <div className="flex flex-row justify-between w-[100%] flex-wrap gap-5">
                            <div className="flex flex-row gap-4 justify-start">
                                <div className="rounded-[50%] h-[100px] w-[100px] flex-shrink-0">
                                    <div style={{ boxShadow: "0px 6px 8px 0px rgba(0,0,0,0.1)" }} className="border-white rounded-[50%] p-[3px]">
                                        <img className="rounded-[50%] aspect-square object-cover h-full w-full" src={`${domain}/static/${profile.profileImage}`} alt="profile" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 my-3 w-[60%]">
                                    <h1 className="font-semibold text-lg">{`${profile.firstName} ${profile.lastName}`}</h1>
                                    <div className="flex flex-row justify-start items-start gap-2">
                                        <Location className="h-6 w-6 mt-[1px]" height="100%" width="100%" />
                                        <h1 className="text-lightGray text-sm mr-5 line-clamp-2">{`${profile.location}, ${profile.landmark}, ${profile.state}`}</h1>
                                    </div>
                                </div>
                            </div>

                            <RatingAndMessages messageLabel="Send a Message" onMessage={onMessage}
                                onReview={() => navigate("/ReviewListScreen", { state: { fetchUrl: `reviews/profile/${profile.uuid}/all` } })}
                                reviews={profile.reviews} reviewsCount={profile.reviewsCount} />
                        </div>

                        <StaggeredListView columns={2} horizontalGap="gap-2" verticalGap="gap-2" pageRequest={fetchCompletedGigs}
                            className="mb-8 mt-5"
                            onNotFound={`${profile.firstName} don't have any completed Jobs`}
                            items={Object.values(completedGigs)}
                        />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default ClientPublicProfile;