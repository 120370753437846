import { onMessage } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet, useParams } from "react-router-dom";

import fetch from "../../axios/manager";
import * as Cache from "../../cache/cachemanager";
import * as Loader from "../../dialogs/loader/loaderop";
import showToast from "../../dialogs/loader/toast";
import { getFcmToken, messaging, subscribeToTopic } from "../../integrations/firebaseconfig";
import * as notificationRedux from "../../redux/slices/notifications";
import { updateCompletion, updateProfile, updateUser } from "../../redux/slices/user";
import SocketProvider from "../../socket/provider";

const AuthHandler = () => {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const userSlice = useSelector((state) => state.userSlice);

    const user = userSlice.user;
    const profile = userSlice.profile;

    const { chatroomUUID, channelUUID } = useParams();

    const [openedChatroomUUID, setOpenedChatroomUUID] = useState(null);
    const [openedChannelUUID, setOpenedChannelUUID] = useState(null);

    useEffect(() => {
        setOpenedChatroomUUID(chatroomUUID);
        setOpenedChannelUUID(channelUUID);
    }, [chatroomUUID, channelUUID]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        onMessage(messaging, async (message) => {
            const data = message.data;
            const topic = data.topic || 'general';
            const forProfileUUID = data.for;

            if (forProfileUUID != null) {
                const profileUUID = profile.uuid;

                if (profileUUID != forProfileUUID) {
                    return;
                }
            }

            let largeImage, image;
            if (data.largeImage != null) {
                largeImage = `https://api.onceusa.com/static/${data.largeImage}`;
            }
            if (data.image != null) {
                image = `https://api.onceusa.com/static/${data.image}`;
            }

            const expanded = data.expanded === 'true';
            const title = data.title || 'Once.';
            const description = data.body || 'Click here to open the notification';

            const options = {
                body: description,
                icon: image || '/icon-192.png',
                image: largeImage,
            };

            if (expanded && largeImage) {
                options.image = largeImage;
            }

            if (topic.startsWith('chatrooms~')) {
                if (data.chatUUID != null) {
                    if (openedChatroomUUID == data.chatroomUUID && openedChannelUUID == data.channelUUID) {
                        return;
                    }
                }
                else if (data.markReadAll == "true") {
                    return;
                }
            }

            new Notification(title, options);
        });
    }, []);

    const init = async () => {
        if (user.id === -1) {
            const prevUserData = await Cache.get("userData");

            dispatcher(updateUser(prevUserData));

            if (prevUserData == null) {
                Cache.removeAll();

                navigate("/");

                return;
            }

            const uuid = prevUserData.uuid;

            Loader.show();

            const [userResult, userError] = await fetch({
                route: `users/${uuid}`,
                requestType: "get",
            });

            if (userError != null) {
                Loader.hide();

                Cache.removeAll();

                navigate("/");

                return;
            }

            dispatcher(updateUser(userResult.res));

            const fcmToken = await getFcmToken();

            if (fcmToken != null) {
                subscribeToTopic(fcmToken, `user-general~${uuid}`);
            }

            const [profileData, profileError] = await fetch({
                route: `profiles/user/${uuid}`,
                requestType: "get",
            });

            if (profileError != null) {
                Loader.hide();

                Cache.removeAll();
                navigate("/");

                return;
            }

            if (profileData.res == null) {
                Loader.hide();

                navigate("/ProfileEdit", { state: { firstTime: true } });
                return;
            }

            const profileUUID = profileData.res.uuid;

            if (fcmToken != null) {
                subscribeToTopic(fcmToken, `chatrooms~${profileUUID}`);
            }

            dispatcher(updateProfile(profileData.res));

            const [profileCompletionData, profileCompletionError, profileCompletionStatus] = await fetch({
                route: `users/${uuid}/profileCompletion`,
                requestType: "get",
            });

            if (profileCompletionError != null) {
                Loader.hide();

                if (profileCompletionStatus == 404) {
                    Cache.removeAll();
                    navigate("/");
                    return;
                }

                if (profileCompletionStatus == 402) {
                    navigate("/ProfileEdit", { state: { firstTime: true } });
                    return;
                }

                return;
            }

            dispatcher(updateCompletion(profileCompletionData.res));

            const profileCompletion = profileCompletionData.res;

            const [notificationsData, notificationError] = await fetch({
                route: "chats/auth/notificationsAll",
                requestType: "get",
            });

            if (notificationError != null) {
                showToast(notificationError);
                Cache.removeAll();
                navigate("/");
                return Loader.hide();
            }

            dispatcher(notificationRedux.setNotificationCount({ count: notificationsData.res ?? 0 }));

            Loader.hide();

            if (profileCompletion.verificationPayment != 1 || profileCompletion.checkrVerification != 1) {
                if (user.userType == "provider") {
                    navigate("/ProviderVerificationScreen");
                }
                else {
                    navigate("/ClientVerificationScreen");
                }
                return;
            }
            else if (profileCompletion.subscription == null) {
                navigate("/SubcriptionPickScreen");
                return;
            }
            else {
                return;
            }
        }
    };

    return <SocketProvider>
        <Outlet />
    </SocketProvider>;
};

export default AuthHandler;