import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import fetch from "../../../axios/manager";
import * as Loader from "../../../dialogs/loader/loaderop";
import showToast from "../../../dialogs/loader/toast";
import PaginatedScrollView from "../../../global/components/paginatedscrollview";
import { setProgress } from "../../../redux/slices/toploadingbar";
import ExpertItem from "../components/expert-item";

const ApplicantsScreen = () => {
    const location = useLocation();
    const dispatcher = useDispatch();
    const { job } = location.state;

    const [applicants, setApplicants] = useState(null);

    const fetchApplicants = async (offset, limit) => {
        if (offset == 0 && applicants != null) {
            return applicants;
        }

        dispatcher(setProgress(50));
        Loader.show();

        const [result, error] = await fetch({
            route: `chats/auth/get/applicants/job/${job?.uuid}`,
            requestType: "get",
            cacheKey: `applicants-job-${job?.uuid}-${offset}`,
            params: {
                "limit": limit,
                "offset": offset,
                "sort": "DESC",
            },
        });

        if (error != null) {
            onError(error);
            return [];
        }

        const data = result.res;

        const applicantsData = [];

        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            applicantsData.push(item);
        }

        setApplicants(state => [
            ...(state ?? []),
            ...applicantsData,
        ]);

        dispatcher(setProgress(100));
        Loader.hide();

        return applicantsData;
    };

    function onError(error) {
        showToast(error);
        dispatcher(setProgress(100));
        return Loader.hide();
    }

    return <main>
        <section>
            <div className="flex flex-col w-[100%]">
                <div className="flex flex-col mx-10 items-center justify-center">
                    <div className="mt-6 w-[100%] xl:px-32 lg:px-20">
                        <h1 className="text-black font-semibold mb-10 self-start text-3xl mt-12">All Applicants</h1>
                        <PaginatedScrollView onNotFound="No applicants yet" pageRequest={fetchApplicants} className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-start gap-5 p-10"
                            child={(item) => {
                                const profile = item.chatroom.otherProfile;

                                return (
                                    <ExpertItem key={item.uuid} profile={profile} reviews={profile.reviews}
                                        job={job} title={`${profile.firstName} ${profile.lastName}`} path={"/chatRooms"} state={{ job: job, chatroomUUID: item.chatroom.uuid }} />
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    </main>;
};

export default ApplicantsScreen;